<template>
  <div
    class="modal fade"
    id="kt_modal_add_contacts"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-medium p-9">
      <div class="modal-content modal-rounded">
        <div class="modal-header py-7 d-flex justify-content-between">
          <h2 class="m-0">Create new contact</h2>
          <div
            id="dismiss-modal"
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            ref="closeModal"
          >
            <i class="ki-solid ki-cross fs-1"></i>
          </div>
        </div>
        <div class="modal-body scroll-y m-5">
            
            <div class="mb-10">
                <label for="name" class="required form-label">Name</label>
                <div class="position-relative">
                    <span class="text-danger" v-if="v$.newContactForm.name.$error">Please enter a contact name</span>
                    <input v-model="newContactForm.name" type="text" id="name" class="form-control" placeholder="">
                </div>
            </div>
            <div class="mb-10">
                <label for="phone" class="required form-label">Phone number</label>
                <div class="position-relative">
                    <span class="text-danger" v-if="v$.newContactForm.phoneNumber.$error">Please enter a phone number</span>
                    <input v-model="newContactForm.phoneNumber" type="tel" id="phone" class="form-control" placeholder="">
                </div>
            </div>
            <div class="mb-10">
                <label for="email" class="required form-label">Email address</label>
                <div class="position-relative">
                    <span class="text-danger" v-if="v$.newContactForm.email.$error">Please enter a valid email address</span>
                    <input v-model="newContactForm.email" type="email" id="email" class="form-control" placeholder="">
                </div>
            </div>
            <div class="mb-10">
                <label for="revenue" class="form-label required">Contact type</label>
                <div class="position-relative">
                  <span class="text-danger" v-if="v$.newContactForm.contactType.$error">Please select a contact type</span>
                  <select v-model="newContactForm.contactType" id="contactType" name="contactType" class="form-select">
                    <option v-for="(type, index) in contactTypes" :key="index" :value="type.value">{{ type.label }}</option>
                  </select>
                </div>
            </div>
            <button class="btn btn-primary me-4" @click="submitForm()">Submit</button>
            <button class="btn btn-light" @click="cancelForm()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
export default {
  name: 'AddContactToClient', 
  data() { 
    return {
      v$ : useVuelidate(),
      clientId: null,
      contactTypes: [],
      newContactForm: {
        name: '',
        phoneNumber: '',
        email: '',
        contactType: null
      }
    }
  },
  created() {
    this.initData();
  },
  validations() {
    return {
        newContactForm: {
            name: { required },
            phoneNumber: { required },
            email: { required, email },
            contactType: { required }
        }
    }
  },
  methods: {
    initData() {
      this.$api.getClientContactTypes().then((r) => {
        this.contactTypes = r.data;
      });
    },
    async submitForm() {
        await this.v$.$validate();
        if (!this.v$.newContactForm.$error) {
            this.$api.addNewClientContact(this.$route.query.id, this.newContactForm).then(() => {
               
                this.$parent.initData();
                this.$refs.closeModal.click();
                this.clearForm();
            });
        }
    },
    async clearForm() {
        this.newContactForm.name = '';
        this.newContactForm.phoneNumber = '';
        this.newContactForm.email = '';
        this.newContactForm.contactType = null;
        this.v$.$reset();
    },
    cancelForm() {
      this.$refs.closeModal.click();
      this.clearForm();
    }
  }
};
</script>
