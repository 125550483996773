<template>
  <div
    class="modal fade"
    id="kt_modal_create_client"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-medium p-9">
      <div class="modal-content modal-rounded">
        <div class="modal-header py-7 d-flex justify-content-between">
          <h2 class="m-0">Create new client</h2>
          <div
            id="dismiss-modal"
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            ref="closeModal"
          >
            <i class="ki-solid ki-cross fs-1"></i>
          </div>
        </div>
        <div class="modal-body scroll-y m-5">
            <div class="mb-10">
                <label for="clientName" class="required form-label">Client name</label>
                <div class="position-relative">
                  <span class="text-danger" v-if="v$.form.clientName.$error">Please enter the client name</span>
                  <input type="text" v-model="form.clientName" id="clientName" class="form-control" :class="{'is-invalid' : v$.form.clientName.$error}" placeholder="eg: Company name LTD"/>
                </div>
            </div>
            <div class="mb-10">
                <label for="address" class="form-label">Address</label>
                <div class="position-relative">
                  <input type="text" v-model="form.address" id="address" class="form-control" placeholder="eg: 1 Main Street"/>
                </div>
            </div>
            <div class="mb-10">
                <label for="city" class="form-label">City</label>
                <div class="position-relative">
                  <input type="text" v-model="form.city" id="city" class="form-control" placeholder="eg: Glasgow"/>
                </div>
            </div>
            <div class="mb-10">
                <label for="postcode" class="form-label">Postcode</label>
                <div class="position-relative">
                  <input type="text" v-model="form.postcode" id="postcode" class="form-control" placeholder="eg: G1 2AB"/>
                </div>
            </div>
            <div class="mb-10">
                <label for="primaryContactName" class="required form-label">Contact name</label>
                <div class="position-relative">
                  <span class="text-danger" v-if="v$.form.primaryContactName.$error">Please enter an contact name</span>
                  <input type="text" v-model="form.primaryContactName" id="primaryContactName" class="form-control" :class="{'is-invalid' : v$.form.primaryContactName.$error}" placeholder="eg: John Smith"/>
                </div>
            </div>
            <div class="mb-10">
                <label for="primaryContactTelephone" class="form-label">Contact telephone number</label>
                <div class="position-relative">
                  <span class="text-danger" v-if="v$.form.primaryContactTelephone.$error">Please enter an contact telephone number that is valid</span>
                  <input type="text" v-model="form.primaryContactTelephone" id="primaryContactTelephone" class="form-control" :class="{'is-invalid' : v$.form.primaryContactTelephone.$error}" placeholder="eg: 0141 555 6655"/>
                </div>
            </div>
            <div class="mb-10">
                <label for="primaryContactEmail" class="form-label">Contact email address</label>
                <div class="position-relative">
                  <span class="text-danger" v-if="v$.form.primaryContactEmail.$error">Please enter an contact email address that is valid</span>
                  <input type="email" v-model="form.primaryContactEmail" id="primaryContactEmail" class="form-control" :class="{'is-invalid' : v$.form.primaryContactEmail.$error}" placeholder="eg: johnsmith@email.com"/>
                </div>
            </div>
            <button class="btn btn-primary me-4" @click="submitForm()">Submit</button>
            <button class="btn btn-light" @click="cancelForm()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { reactive, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email, numeric } from "@vuelidate/validators";
export default {
  name: 'CreateNewClient', 
  data() { 
    return {
      v$ : useVuelidate(),
      form: {
        clientName: '',
        address: '',
        city: '',
        postcode: '',
        primaryContactName: '',
        primaryContactTelephone: '',
        primaryContactEmail: ''
      }
    }
  },
  validations() {
    return {
      form: {
        clientName: { required }, 
        primaryContactName: { required }, 
        primaryContactTelephone: { numeric }, 
        primaryContactEmail: { email } 
      }
    }
  },
  methods: {
    async submitForm() {
        await this.v$.$validate();
        if(!this.v$.$error) {
          this.$api.addNewClient(this.form).then(() => {
            this.cancelForm();
            this.$parent.initData();
          });
        }
    },
    async clearForm() {
      this.form.clientName = '';
      this.form.clientAddress = '';
      this.form.clientCity = '';
      this.form.clientPostcode = '';
      this.form.primaryContactName = '';
      this.form.primaryContactTelephone = '';
      this.form.primaryContactEmail = '';
      this.$v.reset();
    },
    cancelForm() {
      this.$refs.closeModal.click();
      this.clearForm();
    }
  }
};
</script>
