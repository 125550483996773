<template>
    <div class="d-none d-lg-block">
        <div class="table-responsive">
            <table class="table table-row-dashed align-middle gs-0 gy-3 my-0">
                <thead>
                    <tr class="fw-semibold fs-6 text-gray-800 border-bottom border-gray-200">
                        <th>Name &amp; email</th>
                        <th class="ps-0 pb-3 w-150px text-end">Role</th>
                        <th class="p-0 pb-3 w-125px text-end">Action</th>
                    </tr>   
                </thead>
                <tbody>
                    <tr v-for="(user, index) in users" :key="index">
                        <td>
                          <div class="d-flex align-items-center py-1">
                            <avatar-name :data="user.fullName" />
                            <div class="d-flex flex-column align-items-start justify-content-center">
                              <span class="text-gray-900 fs-6 lh-1 mb-2">{{user.fullName}}</span>
                              <span class="text-muted fs-8 fw-semibold lh-1">
                                <i class="fas fa-solid fa-envelope me-2"></i>
                                {{ user.email }}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td class="text-end">{{ user.role }}</td>
                        <td class="text-end">
                          <div v-if="$auth.getRoleForUser() == 1" class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Actions
                                </button>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#kt_modal_edit_user"
                                      :title="'Edit ' + user.firstName"
                                      @click="editUser(user)">Edit user</a></li>
                                    <li><a class="dropdown-item" href="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#kt_modal_delete_user"
                                      :title="'Delete ' + user.firstName"
                                      @click="deleteUser(user)">Delete user</a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </template>
  
<script>
  export default {
    props: ["users"],
    methods: {
      editUser(user) {
        this.$emit('editUser', user)
      },
      deleteUser(user)
      {
        this.$emit('deleteUser', user)
      }
    }
  };
  </script>
  