<template>
    <div
      class="modal fade"
      id="kt_modal_schedule_event"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl p-9">
        <div class="modal-content modal-rounded">
          <div class="modal-header py-7 d-flex justify-content-between">
            <h2 class="m-0">Schedule new event</h2>
            <div
              id="dismiss-modal"
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              ref="closeModal"
            >
              <i class="ki-solid ki-cross fs-1"></i>
            </div>
          </div>
          <div class="modal-body m-5">
            <div class="mb-10">
                <label for="eventType" class="form-label">Event type</label>
                <div class="position-relative">
                  <select v-model="form.eventType" id="eventType" name="eventType" class="form-select" @change="loadDropdowns()">
                    <option v-for="(type, index) in eventTypes" :key="index" :value="type.value">{{ type.label }}</option>
                  </select>
                </div>
            </div>
            <div v-if="form.eventType != null">    
                <div class="mb-10" v-if="form.eventType == 2">
                    <label for="complianceTaskId" class="required form-label">Select task to schedule</label>
                    <div class="position-relative">
                      <span class="text-danger" v-if="v$.form.complianceTaskId.$error">Please select at least one person</span>
                      <select v-model="form.complianceTaskId" id="complianceTaskId" name="complianceTaskId" class="form-select" :class="{'is-invalid' : v$.form.complianceTaskId.$error}">
                        <option v-for="(task, index) in availableTasks" :key="index" :value="task.value">{{ task.label }}</option>
                      </select>
                    </div>
                </div>
                
                <div class="mb-10" v-if="form.eventType > 2">
                    <label for="userProfileId" class="required form-label">Assign to</label>
                    <div class="position-relative">
                      <span class="text-danger" v-if="v$.form.userProfileId.$error">Please select a person</span>
                      <select v-model="form.userProfileId" id="userProfileId" name="userProfileId" class="form-select" :class="{'is-invalid' : v$.form.userProfileId.$error}">
                        <option v-for="(user, index) in availableUsers" :key="index" :value="user.value">{{ user.label }}</option>
                      </select>
                    </div>
                </div>
                <div class="mb-10" v-if="form.eventType < 3 && form.eventType != 0">
                    <label for="userProfileId" class="required form-label">Who is this task for?</label>
                    <div>
                    <span class="text-danger" v-if="v$.form.usersAssigned.$error">Please select at least one person</span>
                    </div>
                    <div class="form-check form-check-custom form-check-lg mb-3" v-for="(user, index) in availableUsers" :key="index">
                        <input class="form-check-input" :aria-label="index" :value="user.value" type="checkbox" :class="{'is-invalid' : v$.form.usersAssigned.$error}" v-model="form.usersAssigned" :id="'user'+index" />
                        <label class="form-check-label text-dark" :for="'user'+index">
                            {{ user.label }}
                        </label>
                    </div>
                </div>
                <div class="mb-10" v-if="form.eventType == 1 || form.eventType == 4">
                    <label for="title" class="required form-label">Title</label>
                    <div class="position-relative">
                      <span class="text-danger" v-if="v$.form.title.$error">Please provide a title</span>
                      <input type="text" v-model="form.title" id="title" class="form-control" :class="{'is-invalid' : v$.form.title.$error}" placeholder="eg: System update meeting"/>
                    </div>
                </div>
                <div class="mb-10">
                    <label for="description" class="form-label">Description</label>
                    <div class="position-relative">
                      <textarea v-model="form.description" id="description" class="form-control" placeholder="eg: Installation of new system"/>
                    </div>
                </div>
                <div class="mb-10">
                    <label for="startDateTime" class="required form-label">Start date/time</label>
                    <div class="position-relative">
                      <span class="text-danger" v-if="v$.form.startDateTime.$error">Please provide a start date and optional time</span>  
                      <VueDatePicker :dark="true" :is-24="true" time-picker-inline v-model="form.startDateTime" format="dd/MM/yyyy HH:mm" position="left" :start-time="startTime" @update:model-value="convertStartDate" >
                        <template #dp-input="{ value }">
                            <input type="text" :value="value" id="startDateTime" class="form-control" :class="{'is-invalid' : v$.form.startDateTime.$error}" placeholder=""/>
                        </template>
                      </VueDatePicker>
                    </div>
                </div>
                <div class="mb-10">
                    <label for="endDateTime" class="form-label">End date/time</label>
                    <div class="position-relative">    
                      <span class="text-danger" v-if="v$.form.endDateTime.$error">Please ensure end date/time is after the start date/time</span>  
                      <VueDatePicker :dark="true" :is-24="true" time-picker-inline v-model="form.endDateTime" format="dd/MM/yyyy HH:mm" position="left" :start-time="endTime" @update:model-value="convertEndDate" >
                        <template #dp-input="{ value }">
                            <input type="text" :value="value" id="endDateTime" class="form-control" placeholder="" :class="{'is-invalid' : v$.form.endDateTime.$error}"/>
                        </template>
                      </VueDatePicker>
                    </div>
                </div>
                <div class="mb-10" v-if="form.eventType == 2 || form.eventType == 1">
                    <label for="vanId" class="form-label" :class="{'required': form.eventType == 2, 'is-invalid' : v$.form.vanId.$error}">Select a van for this task</label>
                    <div class="position-relative">
                      <select v-model="form.vanId" id="vanId" name="vanId" class="form-select" :class="{'is-invalid' : v$.form.vanId.$error}">
                        <option v-for="(van, index) in availableVans" :key="index" :value="van.value">{{ van.label }}</option>
                      </select>
                    </div>
                </div>
            </div>
            <button class="btn btn-primary me-4" :disabled="form.eventType == null" @click="submitForm()">Submit</button>
            <button class="btn btn-light" @click="cancelForm()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </template>

  <style>
  .dp__theme_dark {
   --dp-background-color: #1e1e2d;
   --dp-text-color: #bdbdc5;
   --dp-hover-color: #484848;
   --dp-hover-text-color: #ffffff;
   --dp-hover-icon-color: #959595;
   --dp-primary-color: #2caa3a;
   --dp-primary-text-color: #ffffff;
   --dp-secondary-color: #60677e;
   --dp-border-color: #323248;
   --dp-menu-border-color: #323248;
   --dp-border-color-hover: #aaaeb7;
   --dp-disabled-color: #737373;
   --dp-scroll-bar-background: #212121;
   --dp-scroll-bar-color: #484848;
   --dp-success-color: #00701a;
   --dp-success-color-disabled: #428f59;
   --dp-icon-color: #959595;
   --dp-danger-color: #e53935;
   --dp-highlight-color: rgba(0, 92, 178, 0.2);
   --dp-action-buttons-padding: 6px 17px;   
   --dp-input-padding: 10px 30px 10px 13px;
   --dp-font-family: 'Poppins';
   --dp-button-height: 50px;
   --dp-action-button-height: auto;
}
  </style>

  <script lang="js">
  import moment from "moment";
  import useVuelidate from "@vuelidate/core";
  import { required, requiredIf } from "@vuelidate/validators";
  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css'
  
  export default {
    name: 'ScheduleEvent', 
    components: {
      VueDatePicker
    },
    data() { 
      return {
        v$ : useVuelidate(),
        form: {
          title: '',
          description: '',
          eventType: null,
          startDateTime: '',
          endDateTime: '',
          complianceTaskId: null,
          vanId: null,
          userProfileId: null,
          usersAssigned: []
        },
        startTime: { hours: 7, minutes: 30},
        endTime: { hours: 16, minutes: 0},
        generatedStartDateTime: "",
        generatedEndDateTime: "",
        eventTypes: [],
        availableTasks: [],
        availableVans: [],
        availableUsers: []
      }
    },
    watch: {
      'form.complianceTaskId': function (newVal, oldVal) {
        if (newVal != null && newVal != oldVal) {
          this.$api.getTaskToEdit(newVal).then((r) => {
            this.form.description = r.data.description;
          });
        }
      },
      'form.title': function(newVal, oldVal) {
        if (newVal != oldVal) {
          this.form.description = newVal;
        }
      }
    },
    validations() {
      return {
        form: {
          title: { required: requiredIf(() => {
            return (this.form.eventType == 1 || this.form.eventType == 4) && this.form.eventType != null;
          })},
          userProfileId : { required: requiredIf(() => {
            return this.form.eventType > 2 && this.form.eventType != null;
          })},
          usersAssigned : { required: requiredIf(() => {
            return this.form.eventType < 3 && this.form.eventType != null;
          })},
          complianceTaskId : { required: requiredIf(() => {
            return this.form.eventType == 2 && this.form.eventType != null;
          })},
          vanId : { required: requiredIf(() => {
            return this.form.eventType == 2 && this.form.eventType != null;
          })},
          startDateTime: { required },
          endDateTime: { minValue: () => this.generatedStartDateTime < this.generatedEndDateTime }
        }
      }
    },
    created() {
      this.initData();
    },
    methods: {
      initData() {
        this.$api.getEventTypes().then((r) => {
          this.eventTypes = r.data;
        });
        this.$api.getUsersToAssign().then((r) => {
          this.availableUsers = r.data;
        });
        this.$api.getVanSelectList().then((r) => {
            this.availableVans = r.data;
        });
      },
      loadDropdowns() {
        if (this.form.eventType == 2 || this.form.eventType == 1) {
            this.$api.getTasksToSchedule().then((r) => {
                this.availableTasks = r.data;
            });
        }
      },
      convertStartDate(startDate) {
        this.form.startDateTime = moment(startDate).format('yyyy-MM-DD HH:mm');
        this.generatedStartDateTime = new Date(startDate);
        if(this.form.endDateTime == "") {
          this.generatedEndDateTime = new Date(startDate).setHours(16, 0);
          this.form.endDateTime = moment(startDate).set({h: 16, m: 0}).format('yyyy-MM-DD HH:mm')
        }
      },
      convertEndDate(endDate) {
        this.form.endDateTime = moment(endDate).format('yyyy-MM-DD HH:mm');
        this.generatedEndDateTime = new Date(endDate);
      },
      async submitForm() {
          let startDate = this.form.startDateTime;
          await this.v$.$validate();
          if(!this.v$.$error) {
            this.$api.addScheduledEvent(this.form).then(() => {
              this.cancelForm();
              this.v$.$reset();
              this.$parent.initData(moment(startDate).format('yyyy-MM-DD'));
              this.$parent.$forceUpdate();
            });
          }
      },
      async clearForm() {
        this.form.eventType = null;
        this.form.title = '';
        this.form.description = '';
        this.form.startDateTime = '';
        this.form.endDateTime = '';
        this.form.complianceTaskId = null;
        this.form.usersAssigned = [];
        this.form.vanId = null;
        this.form.userProfileId = null;
        this.v$.$reset();
      },
      cancelForm() {
        this.$refs.closeModal.click();
        this.clearForm();
        this.$parent.$forceUpdate();
      }
    }
  };
  </script>
  