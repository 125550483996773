<template>
  <div
    class="modal fade"
    id="kt_modal_mark_complete"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg p-9">
      <div class="modal-content modal-rounded">
        <div class="modal-header py-7 d-flex justify-content-between">
          <h2 class="m-0">
            Mark job as complete?
          </h2>
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <i class="ki-solid ki-cross fs-1 text-dark"></i>
          </div>
        </div>
        <div class="modal-body scroll-y m-5 p-3">
            <p>Please ensure you have checked off all the items below in order to mark this job as complete</p>

            <div class="alert alert-danger" v-if="v$.form.$invalid && v$.form.$error">
                Please check all items
            </div>

            <div v-if="form.questionList.length > 0" class="mb-8">
              <div v-for="(question, index) in form.questionList" :key="index" class="form-check form-check-custom form-check-lg mb-3">
                  <input class="form-check-input" type="checkbox" v-model="form.questionList[index].value" :id="'question' + index" />
                  <label class="form-check-label text-dark" :for="'question' + index">
                      {{question.label}}
                  </label>
              </div>
            </div>

            <div class="mb-8">
                <label for="notes" class="form-label">Notes</label>
                <div class="position-relative">
                  <textarea v-model="form.notes" id="notes" class="form-control" placeholder=""/>
                </div>
            </div>

            <div v-if="$auth.getRoleForUser() != 3" class="mb-8">
              <div class="form-check form-check-custom form-check-lg mb-3">
                <input class="form-check-input" type="checkbox" v-model="form.jobSheet" id="jobSheet" />
                <label class="form-check-label text-dark" for="jobSheet">
                  Send a job sheet for this job?
                </label>
              </div>
            </div>

            <button class="btn btn-primary me-4" @click="submitForm()" :data-bs-dismiss="!v$.form.$invalid && !v$.form.$error ? 'modal' : ''">Mark as complete</button>
            <button class="btn btn-light" data-bs-dismiss="modal" @click="clearForm()">Cancel</button>

          </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { sameAs, helpers } from "@vuelidate/validators";
export default {
  props: ['job'],
  setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            form: {
                jobId: '',
                questionList: [],
                notes: "",
                jobSheet: true
            }
        }
    },
    validations() {
        return {
            form: {
              questionList: {
                $each: helpers.forEach({
                  value: {checked: sameAs(true)}
                }
              )}
            }
        }
    },
    created() {
        this.init();
    },
     methods: {
        init() {
            this.form.jobId = this.job.id;
            this.$api.getJobSheetChecklist(this.job.revenue).then((r) => {
                this.form.questionList = r.data;
            });
        },
        checkValidation(){
          this.v$.form.$touch();this.v$.$validate();
        },
        async submitForm() {
            await this.v$.$validate();
            this.v$.form.$touch();
            if(!this.v$.$error) {
              this.$api.markAsComplete(this.form.jobId, this.form.notes, this.form.jobSheet).then(() => {
                this.$parent.markAsComplete(this.form.notes);
              });
            }
        },
        clearForm() {
            this.v$.$reset();
            this.form.questionList = [];
            this.form.notes = "";
            this.init();
        },
    }
};
</script>