<template>
    <div class="app-wrapper flex-column flex-row-fluid">
      <div class="app-main flex-column flex-row-fluid">
        <div class="d-flex flex-column flex-column-fluid">
          <page-header title="Planner" :links="links">
            <button
              class="btn btn-primary btn-sm d-block"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_schedule_event"
              v-if="$auth.getRoleForUser() != 3 && $auth.getRoleForUser() != 4"
            >
              Add to planner
            </button>
          </page-header>
          <div class="py-3 py-lg-6 w-100">
            <div class="app-container container-xxl">
              <div class="d-block mt-4">
                <div class="row">
                  <div class="col">
                    <button
                      class="btn btn-primary btn-sm d-inline-block me-auto"
                      @click="previousWeek()"
                    >
                      Previous week
                    </button>
                  </div>
                  <div class="col text-end">
                    <button
                      class="btn btn-primary btn-sm d-inline-block me-auto"
                      @click="nextWeek()" 
                    >
                      Next week
                    </button>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column justify-content-center flex-wrap">
                <!-- <FullCalendar ref="calendar" :options="calendarOptions" @drop="drop" v-if="false" /> -->

                <h2 class="fs-3 mb-7 text-center">Week of: {{ formatDate() }}</h2>
              </div>
              <div class="w-100">                
                <div class="table-responsive">
                <table class="table border-bottom border-gray-400 mb-6">
                  <thead>
                    <tr class="border-bottom border-gray-400">
                      <th class=""></th>
                      <th width="160" class="p-3 text-center bg-body-secondary"><strong>Monday</strong><br>{{ formatWeekDate(0) }}</th>
                      <th width="160" class="p-3 text-center bg-body-tertiary"><strong>Tuesday</strong><br>{{ formatWeekDate(1) }}</th>
                      <th width="160" class="p-3 text-center bg-body-secondary"><strong>Wednesday</strong><br>{{ formatWeekDate(2) }}</th>
                      <th width="160" class="p-3 text-center bg-body-tertiary"><strong>Thursday</strong><br>{{ formatWeekDate(3) }}</th>
                      <th width="160" class="p-3 text-center bg-body-secondary"><strong>Friday</strong><br>{{ formatWeekDate(4) }}</th>
                      <th width="160" class="p-3 text-center bg-body-tertiary"><strong>Saturday</strong><br>{{ formatWeekDate(5) }}</th>
                      <th width="160" class="p-3 text-center bg-body-secondary"><strong>Sunday</strong><br>{{ formatWeekDate(6) }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(event, index) in scheduleData" :key="index" class="py-5 border-bottom border-end border-gray-400">                   
                      <td class="fs-4 px-5 fw-bold border-start border-gray-400">{{event.employeeName}}</td>
                      <td v-for="(day, dIndex) in event.days" :key="dIndex" class="border-start border-gray-400">
                          <button 
                            v-for="(task, tIndex) in day.tasks" :key="tIndex" 
                            class="pt-2 pb-2 ps-3 pe-3 mb-2 rounded d-block text-start w-100" 
                            :style="panelColor(task.eventColor)" 
                            @click="selectTask(task, event.employeeName)" 
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_view_event">
                            <span class="d-block fw-bold fs-5 mb-2 text-dark">{{ task.title }}</span>
                            <div class="d-flex">
                              <span class="d-inline-block me-auto">
                                <i v-if="task.eventType == 4" aria-hidden="true" class="fs-3 fa fa-user-shield" :style="'color:' + task.eventColor"></i>
                                <i v-if="task.eventType == 1" aria-hidden="true" class="fs-3 fa fa-handshake" :style="'color:' + task.eventColor"></i>
                                <i v-if="task.eventType == 2" aria-hidden="true" class="fs-3 fa fa-truck-front" :style="'color:' + task.eventColor"></i>
                                <i v-if="task.eventType == 3" aria-hidden="true" class="fs-3 fa fa-plane-departure" :style="'color:' + task.eventColor"></i>
                              </span>
                              <span class="d-inline-block ms-4" v-if="task.eventType == 2 && task.completed" title="Job marked as complete">
                                <i class="fs-3 fa fa-circle-check text-success"></i>
                              </span>
                            </div>
                          </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                </div>
                <div class="d-block mt-4">
                  <div class="row">
                    <div class="col">
                      <button
                        class="btn btn-primary btn-sm d-inline-block me-auto"
                        @click="previousWeek()"
                      >
                        Previous week
                      </button>
                    </div>
                    <div class="col text-end">
                      <button
                        class="btn btn-primary btn-sm d-inline-block me-auto"
                        @click="nextWeek()" 
                      >
                        Next week
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <modal-schedule-event />
      <modal-view-event :task="selectedTask" :user="selectedUser" />
      <modal-reschedule-event :task="selectedTask" :user="selectedUser" />
    </div>
  </template>
  
<script>
  import moment from "moment";
  export default {
    name: "Planner",
    data() {
      return {
        weekBeginning: null,
        scheduleData: [],
        selectedTask: null,
        selectedUser: null,
        links: [
          {
            url: "/",
            title: "Dashboard",
          },
        ],
      };
    },
    created() {
      this.initData(moment().format('yyyy-MM-DD'));
    },
    methods: {
      initData(date) {
        this.$api.getCompanySchedule(date).then((r) => {
          this.weekBeginning = r.data.weekBeginning;
          this.scheduleData = r.data.employeeSchedules;
        });
      },
      hexToRgbA(hex){
          var c;
          if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
              c= hex.substring(1).split('');
              if(c.length== 3){
                  c= [c[0], c[0], c[1], c[1], c[2], c[2]];
              }
              c= '0x'+c.join('');
              return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.1)';
          } else {
            return 'transparent';
          }
      },
      panelColor(hex) {
        const background = this.hexToRgbA(hex);
        let border = 'border: 2px solid' + hex + ' !important; --bs-border-opacity: 1;';
        let bgColor = 'background-color:' + background + ';';
        let style = border + bgColor;
        return style; 
      },
      selectTask(task, user) {
        this.selectedTask = task;
        this.selectedUser = user;
        this.showModal = true;
      },
      
      rescheduleTask(task, user) {
        this.selectedTask = task;
        this.selectedUser = user;
      },
      deleteTask(task) {
        this.$api.deleteScheduledEvent(task).then(() => {
          this.initData(moment().format('yyyy-MM-DD'));
          this.$forceUpdate();
        });
      },
      previousWeek() {
        let lastWeek = moment(this.weekBeginning).add(-7, 'days');
        this.initData(lastWeek.format('yyyy-MM-DD'));
      },
      nextWeek() {
        let nextWeek = moment(this.weekBeginning).add(7, 'days');
        this.initData(nextWeek.format('yyyy-MM-DD'));
      },
      formatDate() {
        return moment(this.weekBeginning).format('DD/MM/yyyy');
      },
      
      formatWeekDate(day) {
        return moment(this.weekBeginning).add(day, 'days').format('DD/MM/yyyy');
      }
    }
  };
  </script>
 