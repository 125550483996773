<template>
    <div class="card h-100">
      <div class="card-header flex-nowrap border-0">
        <div class="card-title mt-8">
          <avatar-name :data="user.fullName" />
          <h3 class="ms-3 fs-3 me-1">{{ user.fullName }}</h3>
        </div>
        <div class="card-toolbar">
            <button
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_edit_user"
              :title="'Edit ' + user.firstName"
              @click="editUser(user)"
            >
              <i class="fas fa-edit fs-3"></i>
            </button>
          </div>
      </div>
      <div class="card-body d-flex flex-column px-9 pt-4 pb-4">
        <div class="d-flex mb-4">
          <i class="fas fa-solid fa-envelope me-4 fs-4"></i>
          <p class="m-0">
            <span class="text-muted d-block fs-7">Email Address</span>
            {{ user.email }}
          </p>
        </div>
        <div class="d-flex mb-4">
          <i class="fas fa-solid fa-circle-user me-4 fs-4"></i>
          <p class="m-0">
            <span class="text-muted d-block fs-7">Role</span>
            {{ user.role }}
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: ["user"],
    methods: {
      editUser(user) {
        this.$emit('editUser', user)
      }
    }
  };
  </script>
  