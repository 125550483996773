<template>
    <div
      class="modal fade"
      id="kt_modal_create_job"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-medium p-9">
        <div class="modal-content modal-rounded">
          <div class="modal-header py-7 d-flex justify-content-between">
            <h2 class="m-0">Create new job</h2>
            <div
              id="dismiss-modal"
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              ref="closeModal"
            >
              <i class="ki-solid ki-cross fs-1"></i>
            </div>
          </div>
          <div class="modal-body scroll-y m-5">
            <div class="mb-10">
                <p class="form-label">Please select a client type</p>
                <div class="form-check form-check-custom form-check-solid mb-4">
                    <input class="form-check-input" type="radio" id="clientTypeExisting" name="clientType" value="Existing" v-model="form.clientType"/>
                    <label class="form-check-label" for="clientTypeExisting">
                      Existing
                    </label>
                </div>
                <div class="form-check form-check-custom form-check-solid">
                    <input class="form-check-input" type="radio" id="clientTypeNew" name="clientType" value="New" v-model="form.clientType"/>
                    <label class="form-check-label" for="clientTypeNew">
                      New
                    </label>
                </div>
            </div>
            <div class="mb-10" v-if="form.clientType == 'New'">
                <label for="clientName" class="required form-label">Client name</label>
                <div class="position-relative">
                  <span class="text-danger" v-if="v$.form.clientName.$error">Please provide a client name</span>
                  <input type="text" v-model="form.clientName" id="clientName" class="form-control" :class="{'is-invalid' : v$.form.clientName.$error}" placeholder="eg: Company name LTD"/>
                </div>
            </div>
            <div class="mb-10" v-if="form.clientType == 'New'">
                <label for="contactName" class="form-label">Contact name</label>
                <div class="position-relative">
                  <span class="text-danger" v-if="v$.form.contactName.$error">Please provide a contact name</span>
                  <input type="text" v-model="form.contactName" id="contactName" class="form-control" :class="{'is-invalid' : v$.form.contactName.$error}" placeholder="eg: John Doe"/>
                </div>
            </div>
            <div class="mb-10" v-if="form.clientType == 'New'">
                <label for="contactPhoneNumber" class="form-label">Contact phone number</label>
                <div class="position-relative">
                  <span class="text-danger" v-if="v$.form.contactPhoneNumber.$error">Please provide a contact number</span>
                  <input type="text" v-model="form.contactPhoneNumber" id="contactPhoneNumber" class="form-control" :class="{'is-invalid' : v$.form.contactPhoneNumber.$error}" placeholder="eg: 07979464131"/>
                </div>
            </div>
            <div class="mb-10" v-if="form.clientType == 'Existing'">
                <label for="clientId" class="required form-label">Select existing client</label>
                <div class="position-relative">
                  <select v-model="form.clientId" id="clientId" name="clientId" class="form-select" @change="loadContacts()">
                    <option v-for="(client, index) in clientList" :key="index" :value="client.value">{{ client.label }}</option>
                  </select>
                </div>
            </div>
            <div class="mb-10" v-if="form.clientType == 'Existing' && form.clientId != 0">
                <label for="clientContactId" class="required form-label">Select client contact</label>
                <div class="position-relative">
                  <select v-model="form.clientContactId" id="clientId" name="clientContactId" class="form-select">
                    <option v-for="(contact, index) in clientContacts" :key="index" :value="contact.value">{{ contact.label }}</option>
                  </select>
                </div>
            </div>
            <div class="mb-10">
                <label for="description" class="required form-label">Job description</label>
                <div class="position-relative">
                  <span class="text-danger" v-if="v$.form.description.$error">Please provide a description</span>
                  <textarea v-model="form.description" id="description" class="form-control" :class="{'is-invalid' : v$.form.description.$error}" placeholder="eg: Installation of new system"/>
                </div>
            </div>
            <div class="mb-10">
                <label for="status" class="form-label">Status</label>
                <div class="position-relative">
                  <input type="text" v-model="form.status" id="status" class="form-control" placeholder="eg: Awaiting quotation visit"/>
                </div>
            </div>
            <div class="mb-10">
                <label for="recurringTask" class="form-label">Does this task occur multiple times?</label>
                <div class="position-relative">
                  <input type="checkbox" v-model="form.recurringTask" id="recurringTask" class="form-check-input" />
                </div>
            </div>
            <div class="mb-10" v-if="form.recurringTask == true">
                <label for="recurrances" class="form-label">Number of times this task occurs</label>
                <div class="position-relative">
                  <input type="text" v-model="form.recurrances" id="recurrances" class="form-control" placeholder="eg: 4"/>
                </div>
            </div>
            <div class="mb-10">
                <label for="financialYear" class="form-label">Financial Year</label>
                <div class="position-relative">
                  <select v-model="form.financialYear" id="financialYear" name="financialYear" class="form-select">
                    <option v-for="(financialYear, index) in financialYearList" :key="index" :value="financialYear.value">{{ financialYear.label }}</option>
                  </select>
                </div>
            </div>
            <div class="mb-10">
                <label for="revenue" class="form-label">Revenue stream</label>
                <div class="position-relative">
                  <select v-model="form.revenue" id="revenue" name="revenue" class="form-select">
                    <option v-for="(revenue, index) in revenueList" :key="index" :value="revenue.value">{{ revenue.label }}</option>
                  </select>
                </div>
            </div>
            <div class="mb-10">
                <label for="revenue" class="form-label">Responsible user</label>
                <div class="position-relative">
                  <select v-model="form.responsibleUserId" id="responsibleUserId" name="responsibleUserId" class="form-select">
                    <option v-for="(user, index) in responsibleUsers" :key="index" :value="user.value">{{ user.label }}</option>
                  </select>
                </div>
            </div>
            <button class="btn btn-primary me-4" @click="submitForm()">Submit</button>
            <button class="btn btn-light" @click="cancelForm()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import { required, numeric, requiredIf } from "@vuelidate/validators";
export default {
  name: 'CreateNewJob', 
  data() { 
    return {
      v$ : useVuelidate(),
      form: {
        clientType: 'Existing',
        clientId: 0,
        clientName: '',
        clientContactId: 0,
        contactName: '',
        contactPhoneNumber: '',
        description: '',
        status: '',
        recurringTask: false,
        recurrances: 2,
        financialYear: moment().year(),
        revenue: 0,
        responsibleUserId: 1,
      },
      clientList: [],
      clientContacts: [],
      revenueList: [],
      financialYearList: [],
      responsibleUsers: []
    }
  },
  validations() {
    return {
      form: {
        clientName: { requiredIf: requiredIf(this.form.clientType == 'New') }, 
        contactName: { requiredIf: requiredIf(this.form.clientType == 'New' && this.form.contactPhoneNumber == '') }, 
        contactPhoneNumber: { requiredIf: requiredIf(this.form.clientType == 'New' && this.form.contactName == ''), numeric },
        description: { required },
        responsibleUserId: { required },
        recurrances: { requiredIf: requiredIf(this.form.recurringTask == true), numeric }
      }
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.$api.getClientSelectList().then((r) => {
        this.clientList = r.data;
      });
      this.$api.getRevenueTypes().then((r) => {
        this.revenueList = r.data;
      });
      this.$api.getFinancialYears().then((r) => {
        this.financialYearList = r.data;
      });
      this.$api.getResponsibleUsers().then((r) => {
        this.responsibleUsers = r.data;
      });
    },
    loadContacts() {
      this.$api.getClientContactList(this.form.clientId).then((r) => {
        this.clientContacts = r.data;
      });
    },
    async submitForm() {
        await this.v$.$validate();
        if(!this.v$.$error) {
          this.$api.addNewTask(this.form).then(() => {
            this.cancelForm();
            this.$parent.initData();
          });
        }
    },
    async clearForm() {
      this.clientContacts = [];
      this.form.clientType = 'Existing';
      this.form.clientId = 0;
      this.form.clientName = '';
      this.form.contactName = '';
      this.form.contactPhoneNumber = '';
      this.form.clientContactId = 0;
      this.form.description = '';
      this.form.plannedDate = '';
      this.form.status = '';
      this.form.financialYear = moment().year();
      this.form.revenue = 0;
    },
    cancelForm() {
      this.$refs.closeModal.click();
      this.clearForm();
    }
  }
};
</script>