<template>
    <div
      class="modal fade"
      id="kt_modal_delete_van"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-medium p-9">
        <div class="modal-content modal-rounded">
          <div class="modal-header py-7 d-flex justify-content-between">
            <h2 class="m-0">Delete a van</h2>
            <div
              id="dismiss-modal"
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              ref="closeModal"
            >
              <i class="ki-solid ki-cross fs-1"></i>
            </div>
          </div>
          <div class="modal-body scroll-y m-5">
              <div class="mb-10">
                <p>Are you sure you want to delete the van {{ this.make }} {{ this.model }} with registration plate {{ this.vehicleRegistration }}</p>
              </div>
              <button class="btn btn-primary me-4" @click="submitForm()">Delete</button>
              <button class="btn btn-light" @click="cancelForm()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  //import { reactive, ref } from "vue";
  export default {
    name: 'DeleteVan', 
    props: ["van"],
    data() { 
      return {
        id: 0,
        vehicleRegistration: '',
        make: '',
        model: '',
      }
    },
    watch: {
      van: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.id = this.van.id;
          this.vehicleRegistration = this.van.vehicleRegistration;
          this.make = this.van.make;
          this.model = this.van.model;
        }
      }
    },
    methods: {
      async submitForm() {
        
        this.$api.deleteVan(this.id).then(() => {
            this.cancelForm();
            this.$parent.initData();
        });
      },
      async clearForm() {
        this.id = 0;
        this.vehicleRegistration = '';
        this.make = '';
        this.model = '';
      },
      cancelForm() {
        this.$refs.closeModal.click();
        this.clearForm();
      }
    }
  };
  </script>
  