<template>
<div class="d-none d-lg-block">
    <table class="table table-row-dashed align-middle gs-0 gy-3 my-0">
        <thead>
            <tr class="fw-semibold fs-6 text-gray-800 border-bottom border-gray-200">
                <th class="ps-2 pb-3 w-150px">Job ID</th>
                <th class="pb-3 w-150px">Client name</th>
                <th class="pb-3">Description</th>
                <th class="pb-3 w-150px">Stage</th>
                <th class="pb-3">Planned date</th>
                <th class="pe-2 pb-3 w-150px text-end">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(job, index) in jobs" :key="index" :class="stageBackground(job.stage, job.enquiryDate)">
                <td class="ps-2">
                    <span v-if="ifOutdated(job.enquiryDate) && job.stage == 'New Enquiry'" class="mx-2">
                        <i aria-hidden="true" class="fs-3 fa fa-exclamation-triangle outdated-icon"></i>
                    </span>
                    <span class="fw-bold">#{{job.jobNumber}}</span>
                </td>
                <td>
                    <span class="fw-bold">{{ job.clientName }}</span>
                </td>
                <td>
                    {{job.description}}
                </td>
                <td>
                    {{job.stage}}
                </td>
                <td>{{ job.plannedDate }}</td>
                <td class="text-end pe-3">
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Actions
                        </button>
                        <ul class="dropdown-menu">
                            <li v-if="showScheduleMenueItem(job.stage)"><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_schedule_job" :title="'Schedule job for ' + job.jobNumber + ' - ' + job.clientName" @click="scheduleJob(job)">Schedule new job</a></li>
                            <li v-if="showEditMenuItem(job.stage)"><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_edit_job" :title="'Edit job for ' + job.jobNumber + ' - ' + job.clientName" @click="editJob(job)">Edit job</a></li>
                            <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_view_job" :title="job.jobNumber + ' - ' + job.clientName + ' job details'" @click="viewSelectedJob(job)">View job details</a></li>
                            <li v-if="showDeleteMenuItem()"><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_delete_job" :title="'Delete ' + job.jobNumber" @click="deleteJob(job)">Delete job</a></li>
                        </ul>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
</template>

<script>
import moment from "moment";
export default {
    props: ["jobs"],
    methods: {
        returnPlannedDate(date) {
            const getDate = moment(date, 'DD/MM/YYYY hh:mm:ss');
            if (date != null) {
                return getDate.format('DD/MM/YYYY');
            } else {
                return 'No planned date'
            }
        },
        returnEnquiryDate(date) {
            const getDate = moment(date, 'DD/MM/YYYY hh:mm:ss');
            if (date != null) {
                return getDate.format('DD/MM/YYYY');
            } else {
                return 'No enquiry date'
            }
        },
        editJob(job) {
            this.$emit('editJob', job);
        },
        viewSelectedJob(job) {
            this.$emit('viewJob', job);
        },
        deleteJob(job) {
            this.$emit('deleteJob', job);
        },
        scheduleJob(job) {
            this.$emit('scheduleJob', job);
        },
        ifOutdated(enquiryDate) {
            var today = moment(new Date(), 'DD/MM/YYYY hh:mm:ss');
            var createdDate = moment(enquiryDate, 'DD/MM/YYYY hh:mm:ss');
            var diff = today.diff(createdDate, 'days');
            if (diff >= 14) {
                return true;
            } else {
                return false;
            }
        },
        showScheduleMenueItem(stage) {
            let userRoleId = this.$auth.getRoleForUser();
            switch (stage) {
                case 'New Enquiry':
                    return false;
                case 'Quoted':
                    return false;
                case 'Live Order':
                    return userRoleId != 3 && userRoleId != 4;
                case 'Complete/Ready To Invoice':
                    return userRoleId != 3 && userRoleId != 4;
                case 'Invoiced':
                    return userRoleId != 3 && userRoleId != 4;
                case 'Payment Received':
                    return userRoleId != 3 && userRoleId != 4;
                case 'Lost Job':
                    return false;
            }
        },
        showEditMenuItem(stage) {
            let userRoleId = this.$auth.getRoleForUser();
            if (userRoleId == 4 && (stage != 'Complete/Ready To Invoice' && stage != 'Invoiced')) {
                return false;
            } else {
                return true;
            }
        },
        showDeleteMenuItem() {
            let userRoleId = this.$auth.getRoleForUser();
            return userRoleId != 3 && userRoleId != 4;
        },
        stageBackground(stage, enquiryDate) {
            let classname;
            switch (stage) {
                case 'New Enquiry':
                    classname = 'stage-1 text-light';
                    break;
                case 'Quoted':
                    classname = 'stage-2';
                    break;
                case 'Live Order':
                    classname = 'stage-3 text-light';
                    break;
                case 'Complete/Ready To Invoice':
                    classname = 'stage-4 text-light';
                    break;
                case 'Invoiced':
                    classname = 'stage-5 text-light';
                    break;
                case 'Payment Received':
                    classname = 'stage-6';
                    break;
                case 'Lost Job':
                    classname = 'stage-7 text-light';
                    break;
            }
            if (this.ifOutdated(enquiryDate) && stage == 'New Enquiry') {
                return classname + ' outdated-outline';
            } else {
                return classname;
            }
        }
    }
};
</script>
