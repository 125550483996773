<template>
    <div
      class="modal fade"
      id="kt_modal_create_user"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-medium p-9">
        <div class="modal-content modal-rounded">
          <div class="modal-header py-7 d-flex justify-content-between">
            <h2 class="m-0">Create new user</h2>
            <div
              id="dismiss-modal"
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              ref="closeModal"
            >
              <i class="ki-solid ki-cross fs-1"></i>
            </div>
          </div>
          <div class="modal-body scroll-y m-5">
              <div class="mb-10">
                  <label for="email" class="required form-label">Email address</label>
                  <div class="position-relative">
                    <span class="text-danger" v-if="v$.form.email.$error">Please enter a valid email address</span>
                    <input type="text" v-model="form.email" id="email" class="form-control" :class="{'is-invalid' : v$.form.email.$error}" placeholder="eg: dale@compliancelev.co.uk"/>
                  </div>
              </div>
              <div class="mb-10">
                  <label for="firstName" class="required form-label">First name</label>
                  <div class="position-relative">
                    <span class="text-danger" v-if="v$.form.firstName.$error">Please provide a first name</span>
                    <input type="text" v-model="form.firstName" id="address" class="form-control" :class="{'is-invalid' : v$.form.firstName.$error}" placeholder="eg: Dale"/>
                  </div>
              </div>
              <div class="mb-10">
                  <label for="lastName" class="required form-label">Last Name</label>
                  <div class="position-relative">
                    <span class="text-danger" v-if="v$.form.lastName.$error">Please provide a last name</span>
                    <input type="text" v-model="form.lastName" id="lastName" class="form-control" :class="{'is-invalid' : v$.form.lastName.$error}" placeholder="eg: Taylor"/>
                  </div>
              </div>
              <div class="mb-10">
                  <label for="phoneNumber" class="required form-label">Phone Number</label>
                  <div class="position-relative">
                    <span class="text-danger" v-if="v$.form.phoneNumber.$error">Please provide a phone number</span>
                    <input type="text" v-model="form.phoneNumber" id="phoneNumber" class="form-control" :class="{'is-invalid' : v$.form.phoneNumber.$error}" placeholder="eg: 07123456789"/>
                  </div>
              </div>
              <div class="mb-10">
                  <label for="address1" class="form-label">Address 1</label>
                  <div class="position-relative">
                    <input type="text" v-model="form.address1" id="address1" class="form-control" placeholder="eg: Street name"/>
                  </div>
              </div>
              <div class="mb-10">
                  <label for="address2" class="form-label">Address 2</label>
                  <div class="position-relative">
                    <input type="text" v-model="form.address2" id="address2" class="form-control" placeholder="eg: Flat number"/>
                  </div>
              </div>
              <div class="mb-10">
                  <label for="city" class="form-label">City</label>
                  <div class="position-relative">
                    <input type="text" v-model="form.city" id="city" class="form-control" placeholder="eg: Mauchline"/>
                  </div>
              </div>
              <div class="mb-10">
                  <label for="postcode" class="form-label">Postcode</label>
                  <div class="position-relative">
                    <input type="text" v-model="form.postcode" id="postcode" class="form-control" placeholder="eg: AB12 3CD"/>
                  </div>
              </div>
              <div class="mb-10">
                  <label for="contactName" class="form-label">Contact Name</label>
                  <div class="position-relative">
                    <input type="text" v-model="form.contactName" id="contactName" class="form-control" placeholder="eg: Joe Bloggs"/>
                  </div>
              </div>
              <div class="mb-10">
                  <label for="contactNumber" class="form-label">Contact Number</label>
                  <div class="position-relative">
                    <input type="text" v-model="form.contactNumber" id="contactNumber" class="form-control" placeholder="eg: 07123456789"/>
                  </div>
              </div>
              <div class="mb-10">
                  <label for="password" class="required form-label">Password</label>
                  <div class="position-relative">
                    <span class="text-danger" v-if="v$.form.password.$error">Please ensure password is at least 10 characters long</span>
                    <input type="password" v-model="form.password" id="password" class="form-control" :class="{'is-invalid' : v$.form.password.$error}" />
                  </div>
              </div>
              <div class="mb-10">
                  <label for="passwordConfirmation" class="required form-label">Confirm password</label>
                  <div class="position-relative">
                    <span class="text-danger" v-if="v$.form.passwordConfirmation.$error">Please ensure passwords match</span>
                    <input type="password" v-model="form.passwordConfirmation" id="passwordConfimation" class="form-control" :class="{'is-invalid' : v$.form.passwordConfirmation.$error}" />
                  </div>
              </div>
              <div class="mb-10">
                  <label for="role" class="required form-label">Role</label>
                  <div class="position-relative">
                    <span class="text-danger" v-if="v$.form.role.$error">Please select a role for this user</span>
                    <select v-model="form.role" id="role" name="role" class="form-control" :class="{'is-invalid' : v$.form.role.$error}">
                      <option v-for="(role, index) in rolesToAssign" :key="index" :value="role.value">{{ role.label }}</option>
                    </select>
                  </div>
              </div>
              <button class="btn btn-primary me-4" @click="submitForm()">Submit</button>
              <button class="btn btn-light" @click="cancelForm()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  //import { reactive, ref } from "vue";
  import useVuelidate from "@vuelidate/core";
  import { required, email, numeric, minLength, sameAs } from "@vuelidate/validators";
  export default {
    name: 'CreateNewUser', 
    data() { 
      return {
        v$ : useVuelidate(),
        form: {
          email: '',
          firstName: '',
          lastName: '',
          phoneNumber: '',
          address1: '',
          address2: '',
          city: '',
          postcode: '',
          contactName: '',
          contactNumber: '',
          password: '',
          passwordConfirmation: '',
          role: 3,
        },
        rolesToAssign: []
      }
    },
    created() {
      this.initData();
    },
    validations() {
      return {
        form: {
          email: { required, email },
          firstName: { required },
          lastName: { required },
          phoneNumber: { required },
          password: { required, minLength: minLength(10) }, 
          passwordConfirmation: { required, sameAs: sameAs(this.form.password) }, 
          role: { required, numeric } 
        }
      }
    },
    methods: {
      initData() {
        this.$api.getRolesToAssign().then((r) => {
          this.rolesToAssign = r.data;
        });
      },
      async submitForm() {
          
          await this.v$.$validate();
          if(!this.v$.$error) {
            this.$api.addNewUser(this.form).then(() => {
              this.cancelForm();
              this.$parent.initData();
            });
          }
      },
      async clearForm() {
        this.form.email = '';
        this.form.firstName = '';
        this.form.lastName = '';
        this.phoneNumber = '';
        this.address1 = '';
        this.address2 = '';
        this.city = '';
        this.postcode = '';
        this.contactName = '';
        this.contactNumber = '';
        this.form.password = '';
        this.form.passwordConfirmation = '';
        this.form.role = 3;
      },
      cancelForm() {
        this.$refs.closeModal.click();
        this.clearForm();
      }
    }
  };
  </script>
  