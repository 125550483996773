import Axios from 'axios';
import jwt_decode from "jwt-decode";

export let authPluginInstance;
export class AuthService {
    isAuthenticated = false;
    apiscope = '';
    Api_Base_Url = '';
    install(app, options){
        this.initialize(options);
        authPluginInstance = this;
        app.config.globalProperties.$auth = authPluginInstance
        app.config.globalProperties.$apitoken = '';
        app.config.globalProperties.$roles = [];
    }
    initialize = function(options) {
        this.Api_Base_Url = options.Api_Base_Url;
        this.isAuthenticated = this.getIsAuthenticated();
    }

    async signIn(username, password) {
        await this.acquireApiToken(username, password);
        return this.getIsAuthenticated();
    }

    async signOut() {
        this.isAuthenticated = false;
        localStorage.removeItem('authToken');
        window.location.href = '/login';
    }

    getIsAuthenticated() {
        let _token = this.getAuthToken();
        if (_token == null) {
            return false;
        } else {
            return true;
        }
    }

    async acquireApiToken(username, password) {
        let model = {
            username: username,
            password: password
        };
        await Axios.post(
            this.Api_Base_Url+'/api/authentication/authenticate',
            model
        ).
        then((response) => {
            this.isAuthenticated = true;
            this.setAuthToken(response.data);
        }).
        catch((error) => {
            if (error.response.status === 401) {
                this.isAuthenticated = false;
            }
        });
        
    }

    getUserProfileId() {
        let _token = this.getAuthToken();
        if (_token == null) {
            return 0;
        }
        var decoded = jwt_decode(_token);
        return decoded.sub;
    }

    getLoggedInUserName() {
        let _token = this.getAuthToken();
        if (_token == null) {
            return 0;
        }
        var decoded = jwt_decode(_token);
        return decoded.given_name + ' ' + decoded.family_name;
    }

    getRoleForUser() {
        let _token = this.getAuthToken();
        if (_token == null) {
            return '';
        }
        var decoded = jwt_decode(_token);
        return decoded.role;
    }

    getAuthToken() {
        let _token = localStorage.getItem('authToken') ? JSON.parse(localStorage.getItem('authToken')) : null;
        return _token;
    }

    setAuthToken(token) {
        // if(token.token!=null && token.refreshToken!=null && token.result==true) {
        if (token != null) {
            localStorage.setItem('authToken', JSON.stringify(token));
        }
        
    }
}