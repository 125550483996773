<template>
  <div
    class="modal fade"
    id="kt_modal_view_job"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg p-9">
      <div class="modal-content modal-rounded" v-if="job != null">
        <div class="modal-header py-7 d-flex justify-content-between">
          <h2 class="m-0">
            Job Details
          </h2>
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            ref="closeModal"
          >
            <i class="ki-solid ki-cross fs-1 text-dark"></i>
          </div>
        </div>
        <div class="modal-body scroll-y m-5 p-3">
          <div class="row"> 
            <div
              class="col-sm-6 d-flex mb-4"
              v-if="job.clientName != null && job.clientName != ''"
            >
            <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
              <div class="symbol-label bg-info">
                <i class="fas fa-solid fa-building-user fs-4 text-dark"></i>
              </div>
            </div>
              <p class="m-0">
                <span class="text-muted d-block fs-7">Client name</span>
                {{job.clientName}}
              </p>
            </div>


            <div
              class="col-sm-6 d-flex mb-4"
              v-if="job.jobNumber != null && job.jobNumber != ''"
            >
            
            <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
              <div class="symbol-label bg-info">
                <i class="fas fa-solid fa-calendar fs-4 text-dark"></i>
              </div>
            </div>
              <p class="m-0">
                <span class="text-muted d-block fs-7">Job number</span>
                {{ job.jobNumber }}
              </p>
            </div>

          </div>

          <div class="d-flex border-bottom mb-4"></div>

          <div
            class="d-flex mb-4 border-bottom pb-4"
            v-if="job.description != null && job.description != ''"
          >
            <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
              <div class="symbol-label bg-info">
                <i class="fas fa-solid fa-circle-info fs-4 text-dark"></i>
              </div>
            </div>
            <p class="m-0">
              <span class="text-muted d-block fs-7">Description</span>
              {{ job.description }}
            </p>
          </div>


          <div class="row"> 
            <div
              class="col-sm-6 d-flex mb-4"
              v-if="job.stage != null && job.stage != ''"
            >
            <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
              <div class="symbol-label bg-info">
                <i class="fas fa-solid fa-question-circle fs-4 text-dark"></i>
              </div>
            </div>
              <p class="m-0">
                <span class="text-muted d-block fs-7">Stage</span>
                <jobs-stage-badge :stage="job.stage" />
              </p>
            </div>


            <div
              class="col-sm-6 d-flex mb-4"
              v-if="job.enquiryDate != null && job.enquiryDate != ''"
            >
            
            <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
              <div class="symbol-label bg-info">
                <i class="fas fa-solid fa-calendar fs-4 text-dark"></i>
              </div>
            </div>
              <p class="m-0">
                <span class="text-muted d-block fs-7">Enquiry date</span>
                {{ job.enquiryDate }}
              </p>
            </div>

            <div
              class="col-sm-6 d-flex mb-4"
              v-if="job.contactName != null && job.contactName != ''"
            >
            <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
              <div class="symbol-label bg-info">
                <i class="fas fa-solid fa-user fs-4 text-dark"></i>
              </div>
            </div>
              <p class="m-0">
                <span class="text-muted d-block fs-7">Contact name</span>
                {{ job.contactName }}
              </p>
            </div>

            <div
              class="col-sm-6 d-flex mb-4"
              v-if="job.contactNumber != null && job.contactNumber != ''"
            >
            
            <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
              <div class="symbol-label bg-info">
                <i class="fas fa-solid fa-phone fs-4 text-dark"></i>
              </div>
            </div>
              <p class="m-0">
                <span class="text-muted d-block fs-7">Contact number</span>
                {{ job.contactNumber }}
              </p>
            </div>

            <div
              class="col-sm-6 d-flex mb-4"
              v-if="job.plannedDate != null && job.plannedDate != ''"
            >
            <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
              <div class="symbol-label bg-info">
                <i class="fas fa-solid fa-calendar-plus fs-4 text-dark"></i>
              </div>
            </div>
              <p class="m-0">
                <span class="text-muted d-block fs-7">Planned date</span>
                {{ job.plannedDate }}
              </p>
            </div>

            <div
              class="col-sm-6 d-flex mb-4"
              v-if="job.assignedTo != null && job.assignedTo != ''"
            >
            <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
              <div class="symbol-label bg-info">
                <i class="fas fa-solid fa-user-gear fs-5 text-dark"></i>
              </div>
            </div>
              <p class="m-0">
                <span class="text-muted d-block fs-7">Assigned to</span>
                {{ job.assignedTo }}
              </p>
            </div>

            <div
              class="col-sm-6 d-flex mb-4"
              v-if="
                job.targetCompetionDate != null && job.targetCompetionDate != ''
              "
            >
            <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
              <div class="symbol-label bg-info">
                <i class="fas fa-solid fa-calendar-day fs-4 text-dark"></i>
              </div>
            </div>
              <p class="m-0">
                <span class="text-muted d-block fs-7"
                  >Target competion date</span
                >
                {{ job.targetCompetionDate }}
              </p>
            </div>

            <div
              class="col-sm-6 d-flex mb-4"
              v-if="job.status != null && job.status != ''"
            >
            <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
              <div class="symbol-label bg-info">
                <i class="fas fa-solid fa-calendar fs-4 text-dark"></i>
              </div>
            </div>
              <p class="m-0">
                <span class="text-muted d-block fs-7">Status</span>
                {{ job.status }}
              </p>
            </div>

            <div
              class="col-sm-6 d-flex mb-4"
              v-if="job.value != null && job.value != ''"
            >
            <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
              <div class="symbol-label bg-info">
                <i class="fas fa-solid fa-pound-sign fs-4 text-dark"></i>
              </div>
            </div>
              <p class="m-0">
                <span class="text-muted d-block fs-7">Value</span>
                {{ job.value }}
              </p>
            </div>

            <div
              class="col-sm-6 d-flex mb-4"
              v-if="job.revenue != null && job.revenue != ''"
            >
            <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
              <div class="symbol-label bg-info">
                <i class="fas fa-solid fa-chart-line fs-4 text-dark"></i>
              </div>
            </div>
              <p class="m-0">
                <span class="text-muted d-block fs-7">Revenue</span>
                {{ job.revenue }}
              </p>
            </div>

            <div
              class="col-sm-6 d-flex mb-4"
              v-if="job.dateInvoiced != null && job.dateInvoiced != ''"
            >
            <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
              <div class="symbol-label bg-info">
                <i class="fas fa-solid fa-calendar-check fs-4 text-dark"></i>
              </div>
            </div>
              <p class="m-0">
                <span class="text-muted d-block fs-7">Date involved</span>
                {{ job.dateInvoiced }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["job"],
  methods: {
    closeModal() {
      this.$parent.closeJob();
    },
  },
};
</script>