<template>
  <div class="app-wrapper flex-column flex-row-fluid">
    <div class="app-main flex-column flex-row-fluid">
      <div class="d-flex flex-column flex-column-fluid">
        <page-header title="Clients" :links="links">
          <button
            class="btn btn-primary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_create_client"
            v-if="$auth.getRoleForUser() != 3 && $auth.getRoleForUser() != 4"
          >
            Create new client
          </button>
        </page-header>
        <div class="app-container container-xxl" v-if="!loading">
          <div class="d-flex flex-column justify-content-center flex-wrap">
            <div class="input-group mb-8">
              <input
                type="text"
                class="form-control"
                placeholder="Search clients..."
                v-model="searchTerm"
                aria-label="Search Clients"
                aria-describedby="search-button"
                @keyup.enter="searchClients()"
              />
              <button
                class="btn btn-icon btn-primary"
                type="button"
                id="search-button"
                @click="searchClients()"
              >
                <i class="fas fa-solid fa-search fs-4"></i>
              </button>
            </div>
          </div>
          
          <client-table-list :clients="clients" v-on:editClient="getClient" v-on:deleteClient="getClient"/>

          <div class="row g-6 g-xl-9 d-lg-none">
            <div
              class="col-sm-6 col-xl-4"
              v-for="(c, index) in clients"
              :key="index"
            >
              <client-list-item :client="c" />
            </div>
          </div>
          <div class="d-flex flex-stack flex-wrap pt-10">
            <div class="fs-6 fw-semibold text-gray-700">
              Showing page {{ page }} of {{ totalPages }}
            </div>
            <ul class="pagination">
              <li class="page-item previous" v-if="page > 1">
                <button class="page-link" @click="prevUrl">
                  <i class="previous"></i>&nbsp; Previous
                </button>
              </li>
              <li class="page-item next" v-if="page < totalPages">
                <button class="page-link" @click="nextUrl">
                  Next &nbsp;<i class="next"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <global-loading v-if="loading" />
    <modal-create-client />
    <modal-edit-client v-if="!loading" :client="selectedClient" />
    <modal-delete-client v-if="!loading" :client="selectedClient" />
  </div>
</template>

<script>
import { useRoute } from "vue-router";
export default {
  name: "Clients",
  data() {
    return {
      loading: true,
      clients: [],
      page: null,
      totalPages: null,
      searchTerm: null,
      selectedClient: null,
      links: [
        {
          url: "/",
          title: "Dashboard",
        },
      ],
    };
  },
  created() {
    this.checkQuery();
    this.initData();
  },
  methods: {
    checkQuery() {
      const route = useRoute();
      this.page = route.query.page == null ? 1 : route.query.page;
      this.searchTerm =
        route.query.searchTerm == null ? "" : route.query.searchTerm;
    },
    initData() {
      this.loading = true;
      this.$api.getClientList(this.searchTerm, this.page).then((r) => {
        this.clients = r.data;
        let pagination = JSON.parse(r.headers.get('x-pagination'));
        this.page = pagination.CurrentPage;
        this.totalPages = pagination.TotalPageCount == 0 ? 1 : pagination.TotalPageCount;
        this.loading = false;
      });
    },
    getClient(client) {
      this.selectedClient = client;
    },
    closeJob() {
      this.selectedClient = null;
    },
    navigate(pageNumber) {
      if (this.searchTerm!= null && this.searchTerm.length > 0) {
        this.$router.push({
          query: { page: pageNumber, searchTerm: this.searchTerm },
        });
      } else {
        this.$router.push({ query: { page: pageNumber } });
      }
    },
    searchClients() {
      this.navigate(1);
    },
    prevUrl() {
      this.navigate(this.page - 1);
    },
    nextUrl() {
      this.navigate(this.page + 1);
    },
  },
};
</script>
