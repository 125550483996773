<template>
  <a :href="'/' + link">
    <div class="card h-100" :class="variation != '' ? 'bg-' + variation : ''">
      <div class="card-header flex-nowrap border-0 pt-8 pb-8">
        <div class="card-title d-flex flex-column">
          <span class="fs-2hx fw-bold text-white me-2 lh-1 ls-n2">{{
            value
          }}</span
          ><span class="text-white opacity-50 pt-1 fw-semibold fs-6">{{
            context
          }}</span>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: "InfoCard",
  props: ["variation", "value", "context", "link"],
};
</script>
