<template>
  <div class="app-wrapper flex-column flex-row-fluid">
    <div class="app-main flex-column flex-row-fluid">
      <div class="d-flex flex-column flex-column-fluid">
        <page-header title="Client contacts" :links="links">
          <button
            class="btn btn-primary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_contacts"
            v-if="$auth.getRoleForUser() != 3"
          >
            Add contact
          </button>
        </page-header>
        
        <div class="app-container container-xxl" v-if="!loading">
            <h2>{{ clientName }}</h2>
                <table class="table table-row-dashed align-middle gs-0 gy-3 my-0" v-if="clientContacts.length > 0">
                    <thead>
                        <tr class="fw-semibold fs-6 text-gray-800 border-bottom border-gray-200">
                            <th>Contact name</th>
                            <th>Phone number</th>
                            <th>Email address</th>
                            <th>Client contact type</th>
                            <th class="p-0 pb-3 w-125px text-end">Action</th>
                        </tr>   
                    </thead>
                    <tbody>
                        <tr v-for="(contact, index) in clientContacts" :key="index">
                            <td>{{contact.name}}</td>
                            <td>{{contact.phoneNumber}}</td>
                            <td>{{contact.email}}</td>
                            <td>{{contact.contactTypeText}}</td>
                            <td class="text-end">
                              <div v-if="$auth.getRoleForUser() != 3" class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Actions
                                </button>
                                <ul class="dropdown-menu">
                                      <li><a class="dropdown-item" href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_edit_contact"
                                        :title="'Edit ' + contact.name"
                                        @click="editContact(contact)">Edit contact</a></li>
                                      <li><a class="dropdown-item" href="#"
                                        :title="'Delete ' + contact.name"
                                        @click="deleteContact(contact)">Delete contact</a></li>
                                  </ul>
                              </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            <div v-else>
              <p>No client contacts available for <strong>{{ clientName }}</strong></p>
            </div>
          
        </div>
      </div>
    </div>

    <modal-add-contact-to-client v-if="!loading" />
    <modal-edit-contact-to-client v-if="!loading" :contact="editableContact" />
    <global-loading v-if="loading" />
  </div>
</template>

<script>
export default {
  name: "Contacts",
  data() {
    return {
      loading: true,
      clientContacts: [],
      clientId: null,
      editableContact : null,
      links: [
        {
          url: "/",
          title: "Dashboard",
        },
        {
          url: "/clients",
          title: "Clients",
        },
      ],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
        this.clientId = this.$route.query.id;
        this.clientName = this.$route.query.clientName;
        this.loading = true;
        this.$api.getClientContactListing(this.clientId).then((r) => {
            
            this.clientContacts = r.data;
            this.loading = false;
        });
    },
    editContact(contact) {
      this.editableContact = contact;
    },
    deleteContact(contact) {
      this.editableContact = contact;
    }
  },
};
</script>
