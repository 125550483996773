<template>
    <div class="app-wrapper flex-column flex-row-fluid">
      <div class="app-main flex-column flex-row-fluid">
        <div class="d-flex flex-column flex-column-fluid">
          <page-header title="Vans" :links="links">
            <button
              class="btn btn-primary btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_create_van"
              v-if="$auth.getRoleForUser() == 1"
            >
              Create new van
            </button>
          </page-header>
          <div class="app-container container-xxl">
        
            <van-table-list :vans="vans"  v-on:editVan="getVan" v-on:deleteVan="getVan"/>
  
            <div class="d-flex flex-stack flex-wrap pt-10">
              <div class="fs-6 fw-semibold text-gray-700">
                Showing page {{ page }} of {{ totalPages }}
              </div>
              <ul class="pagination">
                <li class="page-item previous" v-if="page > 1">
                  <button class="page-link" @click="prevUrl">
                    <i class="previous"></i>&nbsp; Previous
                  </button>
                </li>
                <li class="page-item next" v-if="page < totalPages">
                  <button class="page-link" @click="nextUrl">
                    Next &nbsp;<i class="next"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  
      <modal-create-van />
      <modal-edit-van v-if="!loading" :van="selectedVan" />
      <modal-delete-van v-if="!loading" :van="selectedVan"/>

    </div>
  </template>
  
  <script>
  import { useRoute } from "vue-router";
  export default {
    name: "Vans",
    data() {
      return {
        loading: true,
        vans: [],
        page: null,
        totalPages: 1,
        searchTerm: null,
        selectedVan: null,
        links: [
          {
            url: "/",
            title: "Dashboard",
          },
        ],
      };
    },
    created() {
      this.checkQuery();
      this.initData();
    },
    methods: {
      checkQuery() {
        const route = useRoute();
        this.page = route.query.page == null ? 1 : route.query.page;
        this.searchTerm =
          route.query.searchTerm == null ? "" : route.query.searchTerm;
      },
      initData() {
        this.loading = true;
        this.$api.getVanList().then((r) => {
          this.vans = r.data;
        //   let pagination = JSON.parse(r.headers.get('x-pagination'));
        //   this.page = pagination.CurrentPage;
        //   this.totalPages = pagination.TotalPageCount == 0 ? 1 : pagination.TotalPageCount;
          this.loading = false;
        });
      },
      getVan(van) {
        this.selectedVan = van;
      },
      closeJob() {
        this.selectedVan = null;
      },
      navigate(pageNumber) {
        if (this.searchTerm!= null && this.searchTerm.length > 0) {
          this.$router.push({
            query: { page: pageNumber, searchTerm: this.searchTerm },
          });
        } else {
          this.$router.push({ query: { page: pageNumber } });
        }
      },
      searchVans() {
        this.navigate(1);
      },
      prevUrl() {
        this.navigate(this.page - 1);
      },
      nextUrl() {
        this.navigate(this.page + 1);
      },
    },
  };
  </script>
  