<template>
    <div class="d-none d-lg-block">
            <table class="table table-row-dashed align-middle gs-0 gy-3 my-0">
                <thead>
                    <tr class="fw-semibold fs-6 text-gray-800 border-bottom border-gray-200">
                        <th>Client</th>
                        <th>Address</th>
                        <th>City</th>
                        <th>Postcode</th>
                        <th class="p-0 pb-3 w-125px text-end">Action</th>
                    </tr>   
                </thead>
                <tbody>
                    <tr v-for="(client, index) in clients" :key="index">

                      <td>
                        <div class="d-flex align-items-center py-1">
                          <div class="d-flex flex-column align-items-start justify-content-center">
                            <span class="text-gray-900 fs-6 lh-1">{{client.clientName}}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center py-1">
                          <div class="d-flex flex-column align-items-start justify-content-center">
                            <span class="text-gray-900 fs-6 lh-1">{{client.address}}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center py-1">
                          <div class="d-flex flex-column align-items-start justify-content-center">
                            <span class="text-gray-900 fs-6 lh-1">{{client.city}}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center py-1">
                          <div class="d-flex flex-column align-items-start justify-content-center">
                            <span class="text-gray-900 fs-6 lh-1">{{client.postcode}}</span>
                          </div>
                        </div>
                      </td>
                        <td class="text-end">
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Actions
                                </button>
                                <ul class="dropdown-menu">
                                    <li v-if="$auth.getRoleForUser() != 3 && $auth.getRoleForUser() != 4"><a class="dropdown-item" href="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#kt_modal_edit_client"
                                      :title="'Edit ' + client.clientName"
                                      @click="editClient(client)">Edit client</a></li>
                                    <li><button class="dropdown-item"
                                      @click="clientContacts(client)"
                                      :title="'Edit ' + client.clientName">Client contacts</button></li>
                                    <li v-if="$auth.getRoleForUser() != 3 && $auth.getRoleForUser() != 4"><a class="dropdown-item" href="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#kt_modal_delete_client"
                                      :title="'Delete ' + client.clientName"
                                      @click="deleteClient(client)">Delete client</a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
    </div>
  </template>
  
<script>
  export default {
    props: ["clients"],
    methods: {
      editClient(client) {
        this.$emit('editClient', client)
      },
      clientContacts(job) {
        this.$router.push({ name: 'Contacts', query: { id: job.id, clientName: job.clientName }});
      },
      deleteClient(client) {
        this.$emit('deleteClient', client)
      }
    }
  };
</script>
  