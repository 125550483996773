<template>
    <div class="app-wrapper flex-column flex-row-fluid">
      <div class="app-main flex-column flex-row-fluid">
        <div class="d-flex flex-column flex-column-fluid">
          <page-header title="Reports" :links="links" />
          <div>
            <div class="row g-6 g-xl-9"></div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Reports",
    data() {
      return {
        links: [
          {
            url: "/",
            title: "Dashboard",
          },
        ],
      };
    },
    setup() {},
  };
  </script>
  