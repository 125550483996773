// import axios from "axios";
// import { app } from "@/plugins/app";
import axiosInstance from './axiosInstance';

export default class ApiService {
  token;
  expiresOn;
  apiurl;
  settings;
  isRefreshing = false;
  constructor(apiurl) {
    this.apiurl = apiurl;
    let _service = axiosInstance;
    this.service = _service;
  }

  handleSuccess(response) {
    return response;
  }
  handleError = async (error) => {
    console.log('error',error);
    const { config, response : { status, data} } = error;
    console.log('data',data);
    console.log('config',config);
    switch (status) {
      case 401:
        window.location.href = '/login';
        break;
      case 404:
        this.redirectTo(document, '/404')
        break;
      default:
        this.redirectTo(document, '/500')
        break;
    }
    return Promise.reject(error)
  }

  // -----------------
  // ---- Helpers ----
  // -----------------
  get(path, callback) {
    return this.service
      .get(path)
      .then((response) => callback(response.status, response.data));
  }

  patch(path, payload, callback) {
    return this.service
      .request({
        method: "PATCH",
        url: path,
        responseType: "json",
        data: payload,
      })
      .then((response) => callback(response.status, response.data));
  }

  post(path, payload, headers, callback) {
    if (headers != "") {
      return this.service
        .request({
          method: "POST",
          url: path,
          responseType: "json",
          data: payload,
        })
        .then((response) => callback(response.status, response.data));
    } else {
      return this.service
        .request({
          method: "POST",
          url: path,
          responseType: "json",
          data: payload,
          headers: {
            headers
          }
        })
        .then((response) => callback(response.status, response.data));
    }
  }

  put(path, payload, callback) {
    return this.service
      .request({
        method: "PUT",
        url: path,
        responseType: "json",
        data: payload,
      })
      .then((response) => callback(response.status, response.data));
  }
  delete(path, payload, callback)
  {
    return this.service
    .request({
      method: "DELETE",
      url: path,
      responseType: "json",
      data: payload,
    })
    .then((response) => callback(response.status, response.data));
  }

  // -----------------
  // --- API Calls ---
  // -----------------

  // --- Dashboard ---
  getJobsAllocatedToMe = (id) => {
    return this.service.get(this.apiurl+ '/api/dashboard/GetJobsAllocatedToMe/' + id)
  }
  getJobsCompleted = (id) => {
    return this.service.get(this.apiurl+ '/api/dashboard/GetJobsCompleted/' + id)
  }
  getCommentsOnJobs = (id) => {
    return this.service.get(this.apiurl+ '/api/dashboard/GetCommentsOnJobs/' + id)
  }
  getOutstandingQuotations = () => {
    return this.service.get(this.apiurl+ '/api/dashboard/GetOutstandingQuotations')
  }
  getRecentlyUpdatedTasks = (id) => {
    return this.service.get(this.apiurl+ '/api/dashboard/GetRecentlyUpdatedJobs/' + id)
  }

  // --- Task List ---
  getJobList = (data) => {
    return this.service.post(this.apiurl+ '/api/taskList/GetTaskList', data, "")
  }
  getExcelExport = () => {
    return this.service.get(this.apiurl+ '/api/taskList/ExportToExcel')
  }
  getStageTotals = () => {
    return this.service.get(this.apiurl+ '/api/taskList/GetStageTotals')
  }
  getTasksToSchedule = () => {
    return this.service.get(this.apiurl+ '/api/taskList/GetTasksToSchedule')
  }
  getTaskToEdit = (taskId) => {
    return this.service.get(this.apiurl+ '/api/taskList/GetTask?taskId='+taskId)
  }
  getTaskFiles = (taskId) => {
    return this.service.get(this.apiurl+ '/api/taskList/GetTaskFiles/'+taskId)
  }
  getFinancialFiles = (taskId) => {
    return this.service.get(this.apiurl+ '/api/taskList/GetFinancialFiles/'+taskId)
  }
  getDownloadFile = (taskId, fileName) => {
    return this.service.get(this.apiurl+ '/api/taskList/downloadFile/'+taskId+'?fileName=' + fileName)
  }
  getDownloadFinancialFile = (taskId, fileName) => {
    return this.service.get(this.apiurl+ '/api/taskList/downloadFinancialFile/'+taskId+'?fileName=' + fileName)
  }
  getNewestTaskNumber = () => {
    return this.service.get(this.apiurl+ '/api/taskList/GetNewestTaskNumber')
  }
  getJobSheetChecklist = (revenueType) => {
    return this.service.get(this.apiurl+ '/api/taskList/getJobSheetChecklist?revenueType=' + revenueType)
  }
  getRevenueTypes = () => {
    return this.service.get(this.apiurl+ '/api/taskList/GetRevenueTypes')
  }
  getJobStages = () => {
    return this.service.get(this.apiurl+ '/api/taskList/GetJobStages')
  }
  getFinancialYears = () => {
    return this.service.get(this.apiurl+ '/api/taskList/GetFinancialYears')
  }
  addNewTask = (data) => {
    return this.service.post(this.apiurl+ '/api/taskList/AddNewTask', data, "")
  }
  addNewTaskClientContact = (taskId, data) => {
    return this.service.post(this.apiurl+ '/api/taskList/AddNewClientContact/' + taskId, data, "");
  }
  editTask = (taskId, data) => {
    return this.service.put(this.apiurl+ '/api/taskList/EditTask/' + taskId, data)
  }
  markAsComplete = (taskId, notes, jobSheet) => {
    return this.service.put(this.apiurl+ '/api/taskList/MarkAsComplete/' + taskId + '?notes=' + notes + '&jobSheet=' + jobSheet)
  }
  uploadTaskFiles = (taskId, data) => {
    return this.service.post(this.apiurl+ '/api/taskList/UploadTaskFiles/'+ taskId, data, "'Content-Type': 'multipart/form-data'")
  }
  uploadFinancialFiles = (taskId, data) => {
    return this.service.post(this.apiurl+ '/api/taskList/UploadFinancialFiles/'+ taskId, data, "'Content-Type': 'multipart/form-data'")
  }
  addNewTaskNote = (id, note) => {
    return this.service.put(this.apiurl+ '/api/taskList/CreateTaskNote/' + id, note)
  }
  deleteJob = (taskId) => {
    return this.service.delete(this.apiurl+ '/api/taskList/DeleteTask/'+ taskId)
  }
  deleteJobFile = (taskId, fileName) => {
    return this.service.delete(this.apiurl+ '/api/taskList/DeleteTaskFile/'+ taskId + '?fileName=' + fileName)
  }
  deleteFinancialFile = (taskId, fileName) => {
    return this.service.delete(this.apiurl+ '/api/taskList/DeleteFinancialFile/'+ taskId + '?fileName=' + fileName)
  }
  deleteTaskNote = (taskId, taskNoteId) => {
    return this.service.delete(this.apiurl+ '/api/taskList/DeleteTaskNote/'+ taskId + '?taskNoteId=' + taskNoteId)
  }

  // --- Client List ---
  getClientList = (searchTerm, page) => {
    return this.service.get(this.apiurl+ '/api/client/GetClientList?searchTerm='+searchTerm+'&page='+page)
  }
  getClientSelectList = () => {
    return this.service.get(this.apiurl+ '/api/client/GetClientSelectList')
  }
  getClientContactListing = (clientId) => {
    return this.service.get(this.apiurl+ '/api/client/GetClientContactListing?clientId=' + clientId)
  }
  getClientContactList = (clientId) => {
    return this.service.get(this.apiurl+ '/api/client/GetClientContactList?clientId=' + clientId)
  }
  getClientContactTypes = () => {
    return this.service.get(this.apiurl+ '/api/client/GetClientContactTypes')
  }
  addNewClient = (client) => {
    return this.service.post(this.apiurl+ '/api/client/AddNewClient', client, "")
  }
  editClient = (id, client) => {
    return this.service.put(this.apiurl+ '/api/Client/EditClient/' + id, client)
  }
  addNewClientContact = (clientId, data) => {
    return this.service.post(this.apiurl+ '/api/client/AddNewClientContact/' + clientId, data, "")
  }
  editClientContact = (id, data) => {
    return this.service.put(this.apiurl+ '/api/Client/EditClientContact/' + id, data)
  }
  deleteClient = (clientId) => {
    return this.service.delete(this.apiurl+ '/api/Client/DeleteClient/'+ clientId)
  }

  // --- User List ---
  getUserList = () => {
    return this.service.get(this.apiurl+ '/api/userProfile/GetUserList')
  }
  getUsersToAssign = () => {
    return this.service.get(this.apiurl+ '/api/userProfile/GetUsersToAssign')
  }
  getResponsibleUsers = () => {
    return this.service.get(this.apiurl+ '/api/userProfile/GetResponsibleUsers')
  }
  getRolesToAssign = () => {
    return this.service.get(this.apiurl+ '/api/userProfile/GetRolesToAssign')
  }
  addNewUser = (user) => {
    return this.service.post(this.apiurl+ '/api/userProfile/AddNewUser', user, "")
  }
  editUser = (id, user) => {
    return this.service.put(this.apiurl+ '/api/userProfile/EditUser/' + id, user)
  }
  getUserToEdit = (id) => {
    return this.service.get(this.apiurl+ '/api/userProfile/GetUserToEdit/'+ id)
  }
  editUserPassword = (id, user) => {
    return this.service.put(this.apiurl+ '/api/userProfile/EditUserPassword/' + id, user)
  }
  deleteUser = (id) => {
    return this.service.delete(this.apiurl+ '/api/userProfile/DeleteUser/'+ id)
  }

  // --- Van List ---
  getVanList = () => {
    return this.service.get(this.apiurl+ '/api/van/GetListOfVans')
  }
  getVanSelectList = () => {
    return this.service.get(this.apiurl+ '/api/van/GetVanSelectList')
  }
  addNewVan = (van) => {
    return this.service.post(this.apiurl+ '/api/van/AddNewVan', van, "")
  }
  editVan = (id, van) => {
    return this.service.put(this.apiurl+ '/api/van/EditVan/' + id, van)
  }
  deleteVan = (id) => {
    return this.service.delete(this.apiurl+ '/api/van/DeleteVan/'+ id)
  }

  // --- Schedule ---
  getScheduledEvents = (startDate) => {
    return this.service.get(this.apiurl+ '/api/schedule/GetScheduledEvents?startDate=' + startDate)
  }
  getCompanySchedule = (startDate) => {
    return this.service.get(this.apiurl+ '/api/schedule/GetCompanySchedule?startDate=' + startDate)
  }
  getEventTypes = () => {
    return this.service.get(this.apiurl+ '/api/schedule/GetEventTypes')
  }
  addScheduledEvent = (scheduledEvent) => {
    return this.service.post(this.apiurl+ '/api/schedule/AddScheduledEvent', scheduledEvent, "")
  }
  editScheduledEvent = (eventId, scheduledEvent) => {
    return this.service.put(this.apiurl+ '/api/schedule/EditScheduledEvent/' + eventId, scheduledEvent)
  }
  deleteScheduledEvent = (eventId) => {
    return this.service.delete(this.apiurl+ '/api/schedule/DeleteScheduledEvent/' + eventId)
  }
}