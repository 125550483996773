<template>
  <div class="app-toolbar py-3 py-lg-6 w-100">
    <div class="app-container container-xxl d-flex flex-stack w-100">
      <div
        class="page-title d-flex flex-column justify-content-center flex-wrap me-3"
      >
        <h1
          class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0"
        >
          {{ title }}
        </h1>
        <ul
          v-if="links == null || links.length > 0"
          class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1"
        >
          <li
            class="breadcrumb-item text-muted"
            v-for="(link, index) in links"
            :key="index"
          >
            <router-link :to="link.url" class="text-muted text-hover-primary">
              {{ link.title }}
            </router-link>
            <span class="ms-3 bullet bg-gray-400 w-5px h-2px"></span>
          </li>
          <li class="breadcrumb-item text-muted">{{ title }}</li>
        </ul>
      </div>
      <div class="d-flex align-items-center my-2"><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: ["title", "links"],
  setup() {},
};
</script>
