<template>
  <div class="card h-100">
    <div class="card-header flex-nowrap border-0">
      <div class="card-title">
        <avatar-name :data="client.clientName"/>
        <div class="d-flex flex-column align-items-start justify-content-center">
          <span class="text-gray-900 fs-6 lh-1">{{client.clientName}}</span>
        </div>
      </div>
      <div class="card-toolbar">
        <div class="d-flex flex-column align-items-start justify-content-center">
          <button
            class="btn btn-icon btn-bg-light btn-active-color-primary w-30px h-30px"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :title="'Edit ' + client.clientName"
          >
            <i class="fas fa-edit fs-3"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["client"]
};
</script>
