<template>
    <div
      class="modal fade"
      id="kt_modal_schedule_job"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-l p-9">
        <div class="modal-content modal-rounded">
          <div class="modal-header py-7 d-flex justify-content-between">
            <h2 class="m-0">Schedule new job</h2>
            <div
              id="dismiss-modal"
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              ref="closeModal"
            >
              <i class="ki-solid ki-cross fs-1"></i>
            </div>
          </div>
          <div class="modal-body scroll-y m-5">
            <div class="row mb-3" v-if="job != null"> 
            <div
              class="col-sm-6 d-flex mb-4"
              v-if="job.clientName != null && job.clientName != ''"
            >
            <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
              <div class="symbol-label bg-info">
                <i class="fas fa-solid fa-building-user fs-4 text-dark"></i>
              </div>
            </div>
              <p class="m-0">
                <span class="text-muted d-block fs-7">Client name</span>
                {{job.clientName}}
              </p>
            </div>


            <div
              class="col-sm-6 d-flex mb-4"
              v-if="job.jobNumber != null && job.jobNumber != ''"
            >
            
            <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
              <div class="symbol-label bg-info">
                <i class="fas fa-solid fa-calendar fs-4 text-dark"></i>
              </div>
            </div>
              <p class="m-0">
                <span class="text-muted d-block fs-7">Job number</span>
                {{ job.jobNumber }}
              </p>
            </div>

          </div>
          <div class="row" v-if="job != null">
                <div class="col-12 d-flex mb-8" v-if="job.description != null && job.description != ''">
                    <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
                        <div class="symbol-label bg-info">
                            <i class="fas fa-solid fa-info-circle fs-4 text-dark"></i>
                        </div>
                    </div>
                    <p class="m-0">
                        <span class="text-muted d-block fs-7">Job description</span>
                        {{ job.description }}
                    </p>
                </div>
            </div>
            <div>    
                <div class="mb-10">
                    <label for="userProfileId" class="required form-label d-block">Who is this task for?</label>
                    
                    <span class="text-danger" v-if="v$.form.usersAssigned.$error">Please select at least one person</span>
                    <div class="form-check form-check-custom form-check-lg mb-3" v-for="(user, index) in availableUsers" :key="index">
                        <input class="form-check-input" :aria-label="index" :value="user.value" type="checkbox" :class="{'is-invalid' : v$.form.usersAssigned.$error}" v-model="form.usersAssigned" :id="'user'+index" />
                        <label class="form-check-label text-dark" :for="'user'+index">
                            {{ user.label }}
                        </label>
                    </div>
                </div>
                        <div class="mb-10">
                            <label for="startDateTime" class="required form-label">Start date/time</label>
                            <div class="position-relative">
                            <span class="text-danger" v-if="v$.form.startDateTime.$error">Please provide a start date and optional time</span>  
                            <VueDatePicker :dark="true" :is-24="true" time-picker-inline v-model="form.startDateTime" format="dd/MM/yyyy HH:mm" position="left" :start-time="startTime" @update:model-value="convertStartDate" >
                                <template #dp-input="{ value }">
                                    <input type="text" :value="value" id="startDateTime" class="form-control" :class="{'is-invalid' : v$.form.startDateTime.$error}" placeholder=""/>
                                </template>
                            </VueDatePicker>
                            </div>
                        </div>
                        <div class="mb-10">
                            <label for="endDateTime" class="form-label">End date/time</label>  
                            <div class="position-relative">    
                              <span class="text-danger" v-if="v$.form.endDateTime.$error">Please ensure end date/time is after the start date/time</span>    
                              <VueDatePicker :dark="true" :is-24="true" time-picker-inline v-model="form.endDateTime" format="dd/MM/yyyy HH:mm" position="left" :start-time="endTime" @update:model-value="convertEndDate" >
                              <template #dp-input="{ value }">
                                  <input type="text" :value="value" id="endDateTime" class="form-control" :class="{'is-invalid' : v$.form.endDateTime.$error}" placeholder=""/>
                              </template>
                              </VueDatePicker>
                          </div>
                        </div>
                
                
                <div class="mb-10">
                    <label for="vanId" class="form-label required" :class="{'is-invalid' : v$.form.vanId.$error}">Select a van for this task</label>
                    <div class="position-relative">
                        
                    <span class="text-danger" v-if="v$.form.vanId.$error">Please select an van for this task</span>
                      <select v-model="form.vanId" id="vanId" name="vanId" class="form-select" :class="{'is-invalid' : v$.form.vanId.$error}">
                        <option v-for="(van, index) in availableVans" :key="index" :value="van.value">{{ van.label }}</option>
                      </select>
                    </div>
                </div>
            </div>
            <button class="btn btn-primary me-4" @click="submitForm()">Submit</button>
            <button class="btn btn-light" @click="cancelForm()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
</template>

  <style>
  .dp__theme_dark {
   --dp-background-color: #1e1e2d;
   --dp-text-color: #bdbdc5;
   --dp-hover-color: #484848;
   --dp-hover-text-color: #ffffff;
   --dp-hover-icon-color: #959595;
   --dp-primary-color: #2caa3a;
   --dp-primary-text-color: #ffffff;
   --dp-secondary-color: #60677e;
   --dp-border-color: #323248;
   --dp-menu-border-color: #323248;
   --dp-border-color-hover: #aaaeb7;
   --dp-disabled-color: #737373;
   --dp-scroll-bar-background: #212121;
   --dp-scroll-bar-color: #484848;
   --dp-success-color: #00701a;
   --dp-success-color-disabled: #428f59;
   --dp-icon-color: #959595;
   --dp-danger-color: #e53935;
   --dp-highlight-color: rgba(0, 92, 178, 0.2);
   --dp-action-buttons-padding: 6px 17px;   
   --dp-input-padding: 10px 30px 10px 13px;
   --dp-font-family: 'Poppins';
   --dp-button-height: 50px;
   --dp-action-button-height: auto;
}
  </style>

  <script lang="js">
  import moment from "moment";
  import useVuelidate from "@vuelidate/core";
  import { required } from "@vuelidate/validators";
  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css'
  
  export default {
    name: 'ScheduleEvent', 
    components: {
      VueDatePicker
    },
    props: ['job'],
    data() { 
      return {
        v$ : useVuelidate(),
        form: {
          title: '',
          description: '',
          eventType: 2,
          startDateTime: '',
          endDateTime: '',
          complianceTaskId: null,
          vanId: null,
          usersAssigned: []
        },
        generatedEndDateTime: "",
        generatedStartDateTime: "",
        startTime: { hours: 7, minutes: 30},
        endTime: { hours: 16, minutes: 0},
        availableVans: [],
        availableUsers: []
      }
    },
    validations() {
      return {
        form: {
          usersAssigned : { required },
          vanId : { required },
          startDateTime: { required },
          endDateTime: { minValue: () => this.generatedStartDateTime < this.generatedEndDateTime }
        }
      }
    },
    created() {
      this.initData();
    },
    
    watch: {
      job: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.form.complianceTaskId = this.job.id;
          this.form.title = this.job.jobNumber + ': ' + this.job.clientName;
          this.form.description = this.job.description;
        }
      }
    },
    methods: {
      initData() {
        this.$api.getUsersToAssign().then((r) => {
          this.availableUsers = r.data;
        });
        this.$api.getVanSelectList().then((r) => {
            this.availableVans = r.data;
        });
      },
      convertStartDate(startDate) {
        this.form.startDateTime = moment(startDate).format('yyyy-MM-DD HH:mm');
        this.generatedStartDateTime = new Date(startDate);
        if(this.form.endDateTime == "") {
          this.generatedEndDateTime = new Date(startDate).setHours(16, 30);
          this.form.endDateTime = moment(startDate).set({h: 16, m: 30}).format('yyyy-MM-DD HH:mm')
        }
      },
      convertEndDate(endDate) {
        this.form.endDateTime = moment(endDate).format('yyyy-MM-DD HH:mm');
        this.generatedEndDateTime = new Date(endDate);
      },
      async submitForm() {
          await this.v$.$validate();
          if(!this.v$.$error) {
            this.$api.addScheduledEvent(this.form).then(() => {
              this.cancelForm();
              this.v$.$reset();
            });
          }
      },
      async clearForm() {
        this.form.startDateTime = '';
        this.form.endDateTime = '';
        this.form.usersAssigned = [];
        this.form.complianceTaskId = null;
        this.form.vanId = null;
        this.v$.$reset();
      },
      cancelForm() {
        this.$refs.closeModal.click();
        this.clearForm();
      }
    }
  };
  </script>
  