<template>
    <span class="badge" :class="selectClass">{{ stage }}</span>
</template>

<script>
  export default {
    props: ["stage"],
    computed: {
        selectClass() {
            var className;
            switch(this.stage){
                case "New Enquiry" : className = "badge-light-success";
                    break;
                case "Quoted" : className = "badge-light-warning";
                    break;
                case "Live Order" : className = "badge-info";
                    break;
                case "Complete/Ready To Invoice" : className = "badge-tertiary";
                    break;
                case "Payment Received" : className = "badge-success text-light";
                    break;
                case "Invoiced" : className = "badge-light-tertiary";
                    break;                    
                case "Lost Job" : className = "badge-danger";
                    break;
                default : className = 'badge-light-dark';
            }
            return className;
        }
    }
  }
</script>