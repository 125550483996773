<template>
  <div class="card h-100 p-0">
    <div class="card-header flex-nowrap border-0 p-0 m-0">
      <div class="card-title w-100 mx-0 mt-0 mb-5 rounded-top" :class="stageBackground(job.stage)">
        <!-- <avatar-name :data="job.clientName" />     -->
        <span v-if="ifOutdated(job.enquiryDate) && job.stage == 'New Enquiry'" class="ms-6">
            <i aria-hidden="true" class="fs-3 fa fa-exclamation-triangle outdated-icon"></i>
        </span>
        <div class="d-flex flex-column align-items-start justify-content-center px-6 mb-0 me-auto">
          <span class="fw-bold fs-6 lh-1 mb-2">{{job.clientName}}</span>
          <span class="fs-8 fw-regular lh-1">
              #{{ job.jobNumber }}
          </span>
        </div>
        <div class="dropdown mx-4">
        <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Actions
                                </button>
                                <ul class="dropdown-menu">
                                    <li v-if="showScheduleMenueItem(job.stage)"><a class="dropdown-item" href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_schedule_job"
                                        :title="'Schedule job for ' + job.jobNumber + ' - ' + job.clientName"
                                        @click="scheduleJob(job)">Schedule new job</a></li>
                                    <li v-if="showEditMenuItem(job.stage)"><a class="dropdown-item" href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_edit_job"
                                        :title="'Edit job for ' + job.jobNumber + ' - ' + job.clientName"
                                        @click="editJob(job)">Edit job</a></li>
                                    <li><a class="dropdown-item" href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_view_job"
                                        :title="job.jobNumber + ' - ' + job.clientName + ' job details'"
                                        @click="viewSelectedJob(job)"
                                        >View job details</a></li>
                                    <li v-if="showDeleteMenuItem()"><a class="dropdown-item" href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_delete_job"
                                        :title="'Delete ' + job.jobNumber"
                                        @click="deleteJob(job)">Delete job</a></li>
                                </ul>
                                </div>
      </div>
    </div>
    <div class="card-body d-flex flex-column px-5 pt-2 pb-2">
      
      <div class="d-flex mb-4">
        <i class="fas fa-solid fa-circle-info me-4 fs-4"></i>
        <p class="m-0">
          <span class="text-muted d-block fs-7">Description</span>
          {{ job.description }}
        </p>
      </div>
      <div class="d-flex">
          <div class="d-flex mb-4 w-50">
            <i class="fas fa-solid fa-question-circle me-4 fs-4"></i>
            <p class="m-0">
              <span class="text-muted d-block fs-7">Stage</span>
              {{job.stage}}
            </p>
          </div>
          <div class="d-flex mb-4 w-50">
            <i class="fas fa-solid fa-calendar me-4 fs-4"></i>
            <p class="m-0">
              <span class="text-muted d-block fs-7">Planned date</span>
              {{ job.plannedDate }}
            </p>
          </div>
        </div>
    </div>
  </div>
</template>
<style>
    .outdated-mobile-icon {
        animation: outlineText 3s infinite;
    }
    @keyframes outline {
        0%   {outline-color: #f1416c;}
        50%  {outline-color: rgba(0,0,0,0);}
        100% {outline-color: #f1416c;}
    }
    @keyframes outlineText {
        0%   {color: #f1416c;}
        50%  {color: rgba(0,0,0,0);}
        100% {color: #f1416c;}
    }
</style>
<script>
import moment from "moment";
export default {
  props: ["job"],
  data() {
    return {
      colours: [
       "bg-light-success text-success",
       "bg-light-info text-info", 
       "bg-light-warning text-warning", 
       "bg-light-danger text-danger", 
       "bg-light-tertiary text-tertiary",
       "bg-success text-light", 
       "bg-info text-white", 
       "bg-warning text-light", 
       "bg-danger text-white", 
       "bg-tertiary text-light"],
    };
  },
  computed: {
    iconBackground() {
      var chosenNumber = Math.floor(Math.random() * this.colours.length);
      return (
        this.colours[chosenNumber]
      );
    },
  },
  methods: {
    returnEnquiryDate(date) {
        const getDate = moment(date,'DD/MM/YYYY hh:mm:ss');
        if(date != null) {
            return getDate.format('DD/MM/YYYY');
        } else {
            return 'No enquiry date'
        }
    },

    ifOutdated(enquiryDate) {
        var today = moment(new Date());
        var createdDate = moment(enquiryDate, 'DD/MM/YYYY hh:mm:ss');
        var diff = today.diff(createdDate, 'days');
        if (diff >= 14) {
            return true;
        } else {
            return false;
        }
    },
    showScheduleMenueItem(stage) {
        let userRoleId = this.$auth.getRoleForUser();
        switch (stage) {
            case 'New Enquiry':
                return false;
            case 'Quoted':
                return false;
            case 'Live Order':
                return userRoleId != 3 && userRoleId != 4;
            case 'Complete/Ready To Invoice':
                return userRoleId != 3 && userRoleId != 4;
            case 'Invoiced':
                return userRoleId != 3 && userRoleId != 4;
            case 'Payment Received':
                return userRoleId != 3 && userRoleId != 4;
            case 'Lost Job':
                return false;
        }
    },
    showEditMenuItem(stage) {
        let userRoleId = this.$auth.getRoleForUser();
        if (userRoleId == 4 && (stage != 'Complete/Ready To Invoice' && stage != 'Invoiced')) {
            return false;
        } else {
            return true;
        }
    },
    showDeleteMenuItem() {
        let userRoleId = this.$auth.getRoleForUser();
        return userRoleId != 3 && userRoleId != 4;
    },
    editJob(job) {
        this.$emit('editJob', job);
    },
    viewSelectedJob(job) {
        this.$emit('viewJob', job);
    },
    deleteJob(job){
        this.$emit('deleteJob', job);
    },
    scheduleJob(job) {
        this.$emit('scheduleJob', job);
    },
    stageBackground(stage) {
      let classname;
      switch(stage){
          case 'New Enquiry': classname = 'stage-1 text-light'; break;
          case 'Quoted': classname = 'stage-2'; break;
          case 'Live Order': classname = 'stage-3 text-light'; break;
          case 'Complete/Ready To Invoice': classname = 'stage-4 text-light'; break
          case 'Invoiced': classname = 'stage-5 text-light'; break;
          case 'Payment Received': classname = 'stage-6'; break;
          case 'Lost Job': classname = 'stage-7 text-light'; break;
      }
      return classname;
    }
  }
};
</script>
