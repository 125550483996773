<template>
<div class="app-wrapper flex-column flex-row-fluid">
    <div class="app-main flex-column flex-row-fluid" v-if="!loading">
        <div class="d-flex flex-column flex-column-fluid">
            <page-header title="Task list" :links="links">
                <div class="dropdown" v-if="$auth.getRoleForUser() != 3">
                    <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Task list actions
                    </button>
                    <ul class="dropdown-menu">
                        <li v-if="$auth.getRoleForUser() != 3 && $auth.getRoleForUser() != 4"><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_create_job">Add new job</a></li>
                        <li v-if="$auth.getRoleForUser() != 3"><a class="dropdown-item" href="#" @click="exportJobs()">Export task list as Excel</a></li>
                    </ul>
                </div>
            </page-header>
            <div class="app-container container-xxl">
                <div class="row mb-8">
                    <div class="col-7 col-xs-8 col-sm-9 col-md-7 col-xl-10">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Search jobs..." v-model="searchTerm" aria-label="Search Task List" aria-describedby="search-button" @keyup.enter="searchJobs()" />
                            <button class="btn btn-icon btn-primary " type="button" id="search-button" @click="searchJobs()">
                                <i class="fas fa-solid fa-search fs-4"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-5 col-xs-4 col-sm-3 col-md-5 col-xl-2 text-end">
                        <button class="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_filter_job">
                            <span v-text="false ? 'Edit' : 'Apply'"></span> Filter
                        </button>
                    </div>
                </div>

                <div class="row g-6 g-xl-9 mb-4" v-if="($auth.getRoleForUser() != 3 && $auth.getRoleForUser() != 4) && (!completed && !overdue)">
                    <div class="col">
                        <stage-total-card variation="stage-1" context="New Enquiry" @click="filterByStage(1)"></stage-total-card>
                    </div>
                    <div class="col">
                        <stage-total-card variation="stage-2" :value="quotedTotal" context="Quoted" @click="filterByStage(2)"></stage-total-card>
                    </div>
                    <div class="col">
                        <stage-total-card variation="stage-3" :value="liveJobTotal" context="Live Orders" @click="filterByStage(3)"></stage-total-card>
                    </div>
                    <div class="col">
                        <stage-total-card variation="stage-4" :value="readyToInvoiceTotal" context="Ready to Invoice" @click="filterByStage(4)"></stage-total-card>
                    </div>
                    <div class="col">
                        <stage-total-card variation="stage-5" :value="invoicedTotal" context="Invoiced" @click="filterByStage(5)"></stage-total-card>
                    </div>
                    <div class="col">
                        <stage-total-card variation="stage-6" :value="paymentReceivedTotal" context="Payment Received" @click="filterByStage(6)"></stage-total-card>
                    </div>
                    <div class="col">
                        <stage-total-card variation="stage-7" :value="lostJobTotal" context="Lost Jobs" @click="filterByStage(7)"></stage-total-card>
                    </div>
                    <div class="col-12">
                        <button class="card h-100 mb-4 text-start p-0 w-100" @click="filterByStage()" title="Total turnover">
                            <div class="card-header flex-nowrap border-0 pt-8 pb-4">
                                <span class="fas fa-chart-line fs-2 fs-lg-1 me-4 text-primary"></span>
                                <div class="card-title d-flex flex-column m-0">
                                    <span class="fs-3 fw-bold me-2 lh-1 ls-n2">Total turnover</span>
                                    <span class="opacity-75 pt-1 fw-semibold fs-6">{{turnoverTotal}}</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>

                <jobs-table-list :jobs="tasks" v-on:editJob="editJob" v-on:viewJob="getJob" v-on:deleteJob="getJob" v-on:scheduleJob="scheduleJob" v-if="tasks.length > 0 && loading == false" />

                <div class="row g-6 g-xl-9 d-lg-none" v-if="tasks.length > 0 && loading == false">
                    <div class="col-sm-6 col-xl-4" v-for="(c, index) in tasks" :key="index">
                        <jobs-list-item :job="c" v-on:editJob="editJob" v-on:viewJob="getJob" v-on:deleteJob="getJob" v-on:scheduleJob="scheduleJob" />
                    </div>
                </div>
                <div class="d-flex mt-8" v-if="tasks.length == 0 && loading == false">
                    <p class="fs-4">Sorry, Could not find any jobs based on your search criteria</p>
                </div>
                <div class="d-flex flex-stack flex-wrap pt-10">
                    <div class="fs-6 fw-semibold text-gray-700">
                        Showing page {{ page }} of {{ totalPages }}
                    </div>
                    <ul class="pagination">
                        <li class="page-item previous" v-if="page > 1">
                            <button class="page-link" @click="prevUrl">
                                <i class="previous"></i>&nbsp; Previous
                            </button>
                        </li>
                        <li class="page-item next" v-if="page < totalPages">
                            <button class="page-link" @click="nextUrl">
                                Next &nbsp;<i class="next"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <global-loading v-show="loading" />

    <modal-create-job v-show="!loading" />
    <modal-filter-job v-show="!loading" :client="clientId" :stages="stageFilter" :financialYear="financialYear" :userProfiles="userProfileFilter" />
    <modal-view-job v-show="!loading" :job="selectedJob" />
    <modal-schedule-job v-show="!loading" :job="selectedJob" />
    <modal-delete-job v-show="!loading" :job="selectedJob" />
</div>
</template>

<script>
export default {
    name: "Task List",
    data() {
        return {
            loading: true,
            tasks: [],
            page: 1,
            totalPages: null,
            searchTerm: null,
            clientId: null,
            financialYear: null,
            stageFilter: [],
            userProfileFilter: [],
            selectedJob: null,
            quotedTotal: '',
            liveJobTotal: '',
            readyToInvoiceTotal: '',
            invoicedTotal: '',
            paymentReceivedTotal: '',
            lostJobTotal: '',
            turnoverTotal: '',
            assigned: (this.$route.path.includes('assigned') || this.$route.path.includes('completed') || this.$route.path.includes('commented')),
            completed: this.$route.path.includes('completed'),
            commented: this.$route.path.includes('commented'),
            overdue: this.$route.path.includes('overdue'),
            links: [{
                url: "/",
                title: "Dashboard",
            }, ],
        };
    },
    created() {
        this.initData();
    },
    methods: {
        initData() {
            this.loading = true;
            let model = {
                userProfileId: this.$auth.getUserProfileId(),
                searchTerm: this.searchTerm,
                page: this.page,
                clientId: this.clientId,
                financialYear: this.financialYear,
                stageFilter: this.stageFilter,
                userProfileFilter: this.userProfileFilter,
                assigned: this.assigned,
                completed: this.completed,
                commented: this.commented,
                overdue: this.overdue
            };
            this.$api.getJobList(model).then((r) => {
                this.tasks = r.data;
                let pagination = JSON.parse(r.headers.get('x-pagination'));
                this.page = pagination.CurrentPage;
                this.totalPages = pagination.TotalPageCount == 0 ? 1 : pagination.TotalPageCount;
                this.$api.getStageTotals().then((r) => {
                    this.quotedTotal = r.data[0].totalValue;
                    this.liveJobTotal = r.data[1].totalValue;
                    this.readyToInvoiceTotal = r.data[2].totalValue;
                    this.invoicedTotal = r.data[3].totalValue;
                    this.paymentReceivedTotal = r.data[4].totalValue;
                    this.lostJobTotal = r.data[5].totalValue;
                    this.turnoverTotal = r.data[6].totalValue;
                    this.loading = false;
                })
            });
        },
        getJob(job) {
            this.selectedJob = job;
        },
        scheduleJob(job) {
            this.selectedJob = job;
        },
        editJob(job) {
            this.$router.push({ name: 'EditJob', query: { jobId: job.id } });
        },
        closeJob() {
            this.selectedJob = null;
        },
        exportJobs() {
          this.$api.getExcelExport().then((r) => {
            var binaryData = atob(r.data.data);
            const blob = new Blob([new Uint8Array(binaryData.length).map((_, index) => binaryData.charCodeAt(index))], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;
            link.download = 'TaskList.xlsx';
            link.click();
            window.URL.revokeObjectURL(url);
          });
        },
        applyFilter(filter) {
            // API Call to apply filter
            this.clientId = filter.clientId;
            this.stageFilter = filter.stages;
            this.financialYear = filter.financialYear;
            this.userProfileFilter = filter.userProfiles;
            this.page = 1;
            this.initData();
        },
        filterByStage(stage) {
            if (stage != null) {
                this.stageFilter = [stage];
            } else {
                this.stageFilter = [];
            }
            this.page = 1;
            this.initData();
        },
        searchJobs() {
            this.page = 1;
            this.initData();
        },
        prevUrl() {
            this.page = this.page - 1;
            this.initData();
        },
        nextUrl() {
            this.page = this.page + 1;
            this.initData();
        },
    },
};
</script>
