<template>
<div class="app-wrapper flex-column flex-row-fluid">
    <div class="toast bg-primary" :class="{'show': showToast }" role="alert" aria-live="assertive" aria-atomic="true"  style="position: absolute; top: 88px; right: 16px;">
        <div class="toast-body text-dark fs-5">
            <i class="fa-solid fa-circle-check me-4 text-dark" aria-hidden="true"></i>Job has been updated
        </div>
    </div>
    <div class="app-main flex-column flex-row-fluid">
        <div class="d-flex flex-column flex-column-fluid">
            <page-header title="Edit Job" :links="links" />
            <div class="py-3 py-lg-6 w-100">
                <div class="app-container container-xxl">

                    <div class="d-flex flex-column justify-content-center flex-wrap">

                        <ul class="nav nav-tabs nav-line-tabs mb-5 fs-3 fw-bold">
                            <li class="nav-item">
                                <a class="nav-link text-white active" data-bs-toggle="tab" href="#kt_tab_pane_1">Job details</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-white" data-bs-toggle="tab" href="#kt_tab_pane_2">Media &amp; Files</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-white" data-bs-toggle="tab" href="#kt_tab_pane_3">Task notes</a>
                            </li>
                            <li class="nav-item" v-if="$auth.getRoleForUser() != 3">
                                <a class="nav-link text-white" data-bs-toggle="tab" href="#kt_tab_pane_4">Financial files</a>
                            </li>
                        </ul>

                        <div class="tab-content" id="myTabContent" v-if="loaded">
                            <div class="tab-pane fade show active" id="kt_tab_pane_1" role="tabpanel">

                                <div class="row">

                                    <div class="col-7">
                                        <h3 class="mt-4 mb-4">Job details</h3>
                                    </div>
                                    <div class="col-5 text-end">
                                        <button v-if="task.isMarkedAsComplete == false && $auth.getRoleForUser() < 4" data-bs-toggle="modal" data-bs-target="#kt_modal_mark_complete" class="btn btn-info mb-6 me-4">Mark as complete</button>
                                        <button @click="saveJob()" class="btn btn-primary mb-6">Save</button>
                                    </div>
                                </div>

                                <div class="row g-6 g-xl-9 mb-8">
                                    <div class="col-12 col-lg-4">
                                        <div class="card">
                                            <div class="card-header flex-nowrap border-0 py-4 ps-6">
                                                <div class="card-title">
                                                    <div class="symbol symbol-45px w-45px h-45px me-5 bg-light-info text-info">
                                                        <i class="fa-solid fa-users fs-2 mt-4 ms-3 bg-light-info text-info"></i>
                                                    </div>
                                                    <div class=" d-flex flex-column">
                                                        <span class="text-muted d-block fs-7">Client Name</span>
                                                        <span class="text-white pt-1 fw-semibold fs-6">{{ task.clientName }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4">
                                        <div class="card">
                                            <div class="card-header flex-nowrap border-0 py-4 ps-6">
                                                <div class="card-title">
                                                    <div class="symbol symbol-45px w-45px h-45px me-5 bg-light-info text-info">
                                                        <i class="fa-solid fa-truck fs-2 mt-4 ms-3 bg-light-info text-info"></i>
                                                    </div>
                                                    <div class=" d-flex flex-column">
                                                        <span class="text-muted d-block fs-7">Job Number</span>
                                                        <span class="text-white pt-1 fw-semibold fs-6">{{ task.jobNumber }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4">
                                        <div class="card">
                                            <div class="card-header flex-nowrap border-0 py-4 ps-6">
                                                <div class="card-title">
                                                    <div class="symbol symbol-45px w-45px h-45px me-5 bg-light-info text-info">
                                                        <i class="fa-solid fa-calendar-alt fs-2 mt-4 ms-4 bg-light-info text-info"></i>
                                                    </div>
                                                    <div class=" d-flex flex-column">
                                                        <span class="text-muted d-block fs-7">Enquiry date</span>
                                                        <span class="text-white pt-1 fw-semibold fs-6">{{ formatDate(task.enquiryDate) }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row g-6 g-xl-9 mb-8">
                                    <div class="col-12 col-lg-8">
                                        <div class="card mb-10">
                                            <div class="card-body">
                                                <h3 class="mb-6">Job stage & description</h3>

                                                <div class="mb-10">
                                                    <label for="stage" class="required form-label">Stage</label>
                                                    <div class="position-relative">
                                                        <select id="status" class="form-select" placeholder="" v-model="task.stage" :disabled="$auth.getRoleForUser() == 3">
                                                            <option v-for="(stage, index) in jobStages" :key="index" :value="stage.value">{{ stage.label }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="mb-0">
                                                    <label for="description" class="required form-label">Description</label>
                                                    <div class="position-relative">
                                                        <textarea id="description" class="form-control" v-model="task.description" :disabled="$auth.getRoleForUser() == 3"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card mb-10" v-if="$auth.getRoleForUser() != 3">
                                            <div class="card-body">
                                                <h3 class="mb-6">Status & further information</h3>

                                                <div class="mb-10">
                                                    <label for="status" class="required form-label">Status</label>
                                                    <div class="position-relative">
                                                        <input type="text" id="valuation" class="form-control" placeholder="" v-model="task.status">
                                                    </div>
                                                </div>

                                                <div class="mb-10">
                                                    <label for="status" class="required form-label">Client PO Number</label>
                                                    <div class="position-relative">
                                                        <input type="text" id="valuation" class="form-control" placeholder="" v-model="task.poNumber">
                                                    </div>
                                                </div>

                                                <div class="mb-10">
                                                    <label for="valuation" class="required form-label">Valuation</label>
                                                    <div class="position-relative">
                                                        <div class="input-group">
                                                            <span class="input-group-text">£</span>
                                                            <input type="text" id="valuation" class="form-control" placeholder="" v-model="task.value">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="mb-10">
                                                    <label for="revenue" class="required form-label">Revenue</label>
                                                    <div class="position-relative">
                                                        <select id="revenue" class="form-select" placeholder="" v-model="task.revenue">
                                                            <option v-for="(revenue, index) in revenueList" :key="index" :value="revenue.value">{{ revenue.label }}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="mb-10">
                                                    <label for="revenue" class="required form-label">Financial Year</label>
                                                    <div class="position-relative">
                                                        <select id="financialYear" class="form-select" placeholder="" v-model="task.financialYear">
                                                            <option v-for="(financialYear, index) in financialYearList" :key="index" :value="financialYear.value">{{ financialYear.label }}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="mb-0">
                                                    <label for="responsible" class="required form-label">Responsible member of staff</label>
                                                    <div class="position-relative">
                                                        <select id="responsible" class="form-select" placeholder="" v-model="task.responsibleUserId">
                                                            <option v-for="(user, index) in responsibleUsers" :key="index" :value="user.value">{{ user.label }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-4">
                                        <div class="card">
                                            <div class="card-body">
                                                <h3 class="mb-6">Client contact details</h3>

                                                <div class="mb-10" v-if="!addNewContact">
                                                    <label for="contact" class="required form-label">Select contact client</label>
                                                    <div class="position-relative">
                                                        <select id="contact" class="form-select" placeholder="" v-model="task.clientContactId" :disabled="$auth.getRoleForUser() == 3">
                                                            <option v-for="(contact, index) in clientContacts" :key="index" :value="contact.value">{{ contact.label }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <button class="btn btn-primary" @click="toggleContact()" v-if="!addNewContact">Add new contact</button>
                                                <div class="border-success bg-light-success border-dotted p-6 rounded-3 mt-3" v-if="addNewContact">
                                                    <h3 class="mb-10">Add new contact</h3>

                                                    <div class="mb-10">
                                                        <label for="name" class="required form-label">Name</label>
                                                        <div class="position-relative">
                                                            <span class="text-danger" v-if="v$.newContactForm.name.$error">Please enter a contact name</span>
                                                            <input v-model="newContactForm.name" type="text" id="name" class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                    <div class="mb-10">
                                                        <label for="phone" class="required form-label">Phone number</label>
                                                        <div class="position-relative">
                                                            <span class="text-danger" v-if="v$.newContactForm.phoneNumber.$error">Please enter a phone number</span>
                                                            <input v-model="newContactForm.phoneNumber" type="tel" id="phone" class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                    <div class="mb-10">
                                                        <label for="email" class="required form-label">Email address</label>
                                                        <div class="position-relative">
                                                            <span class="text-danger" v-if="v$.newContactForm.email.$error">Please enter a valid email address</span>
                                                            <input v-model="newContactForm.email" type="email" id="email" class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                    <button @click="saveNewContact()" class="btn btn-primary">Add new contact</button>
                                                </div>

                                                <button class="btn btn-primary mt-6" @click="toggleContact()" v-if="addNewContact">Add existing contact</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                                <h3 class="mb-7">Media &amp; files</h3>
                                <div class="row mb-8">
                                    <div class="col ms-auto">
                                        <label class="form-label sr-only" for="file">
                                            Upload Image / File
                                        </label>
                                        <input class="form-control" type="file" multiple @change="uploadFile($event.target.files)" ref="file" id="file" name="file" accept=".png, .jpg, .jpeg, .pdf, .docx, .txt" />
                                    </div>
                                    <div class="col">
                                        <button class="btn btn-primary" @click="submitFile">Upload file</button>
                                    </div>
                                </div>
                                <h3 v-if="files.length > 0">Uploaded media &amp; files</h3>

                                <div class="table-responsive" v-if="files.length > 0">
                                    <table class="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                        <thead>
                                            <tr class="fw-semibold fs-6 text-gray-800 border-bottom border-gray-200">
                                                <th class="pb-3">File</th>
                                                <th class="pb-3">File Size</th>
                                                <th class="pb-3">Upload date</th>
                                                <th class="pb-3 pe-0"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(file, index) in files" :key="index">
                                                <td class="pb-3">
                                                    <i class="fs-3 fas fa-file-image me-3" aria-hidden="true"></i>
                                                    <a href="#" @click="downloadFile(file)">{{ file.name }}</a>
                                                </td>
                                                <td class="pb-3">{{ file.fileSize }}MB</td>
                                                <td class="pb-3">{{ formatDateTime(file.uploadDate) }}</td>
                                                <td class="pb-3 pe-0 text-end">
                                                    <button class="btn btn-secondary" @click="deleteFile(task.id,file.name)">Delete</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-if="files.length == 0">
                                    <p>No files have been uploaded</p>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
                                <h3 class="mb-7" v-if="task.notes.length > 0">History notes</h3>

                                <div class="row mb-6" v-if="task.notes.length > 0">
                                    <div class="col ms-auto">
                                        <div class="card mb-4" v-for="(note, index) in task.notes" :key="index">
                                            <div class="card-body">
                                                <p class="text-muted mb-2">{{ formatDateTime(note.messageDate) }} - Posted by {{ note.commenter }}</p>
                                                <p class="fs-4">{{ note.message }}</p>
                                                <button class="btn btn-secondary" @click="removeComment(note.id)">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div :class="task.notes.length > 0 ? 'border-top pt-10' : ''">
                                            <label class="form-label" for="notes">
                                                Add new note / comment
                                            </label>
                                            <textarea rows="4" class="form-control mb-5" id="notes" v-model="noteForm.message"></textarea>
                                            <button class="btn btn-primary" @click="submitNoteForm()">Add note</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
                                <h3 class="mb-7">Financial files</h3>
                                <div class="row mb-8">
                                    <div class="col ms-auto">
                                        <label class="form-label sr-only" for="file">
                                            Upload File
                                        </label>
                                        <input class="form-control" type="file" multiple @change="uploadFinancialFile($event.target.files)" ref="financialFile" id="financialFile" name="financialFile" accept=".png, .jpg, .jpeg, .pdf, .docx, .txt" />
                                    </div>
                                    <div class="col">
                                        <button class="btn btn-primary" @click="submitFinancialFile">Upload file</button>
                                    </div>
                                </div>
                                <h3 v-if="files.length > 0">Uploaded files</h3>

                                <div class="table-responsive" v-if="financialFiles.length > 0">
                                    <table class="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                        <thead>
                                            <tr class="fw-semibold fs-6 text-gray-800 border-bottom border-gray-200">
                                                <th class="pb-3">File</th>
                                                <th class="pb-3">File Size</th>
                                                <th class="pb-3">Upload date</th>
                                                <th class="pb-3 pe-0"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(file, index) in financialFiles" :key="index">
                                                <td class="pb-3">
                                                    <i class="fs-3 fas fa-file-image me-3" aria-hidden="true"></i>
                                                    <a href="#" @click="downloadFinancialFile(file)">{{ file.name }}</a>
                                                </td>
                                                <td class="pb-3">{{ file.fileSize }}MB</td>
                                                <td class="pb-3">{{ formatDateTime(file.uploadDate) }}</td>
                                                <td class="pb-3 pe-0 text-end">
                                                    <button class="btn btn-secondary" @click="deleteFinancialFile(task.id,file.name)">Delete</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-if="financialFiles.length == 0">
                                    <p>No files have been uploaded</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <modal-mark-complete v-if="loaded" :job="task" />
</div>
</template>

<script>
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import { requiredIf, email } from "@vuelidate/validators";

export default {
    name: "EditJob",
    data() {
        return {
            v$ : useVuelidate(),
            links: [{
                    url: "/",
                    title: "Dashboard",
                },
                {
                    url: "/schedule",
                    title: "Daily schedule",
                },
            ],
            files: [],
            financialFiles: [],
            revenueList: [],
            financialYearList: [],
            jobStages: [],
            clientContacts: [],
            responsibleUsers: [],
            task: null,
            loaded: false,
            addNewContact: false,
            newContactForm: {
                name: '',
                phoneNumber: '',
                email: ''
            },
            images: new FormData(),
            financials: new FormData(),
            showToast: false,
            noteForm: {
                message: '',
                messageDate: moment(),
                userProfileId: this.$auth.getUserProfileId()
            }
        };
    },
    validations() {
        return {
            newContactForm: {
                name: { requiredIf: requiredIf(() => {
                    return this.addNewContact == true;
                }) },
                phoneNumber: { requiredIf: requiredIf(() => {
                    return this.addNewContact == true;
                }) },
                email: { email, requiredIf: requiredIf(() => {
                    return this.addNewContact == true;
                }) }
            }
        }
    },
    created() {
        this.initData();
    },
    methods: {
        initData() {
            let jobId = this.$route.query.jobId;
            this.$api.getRevenueTypes().then((r) => {
                this.revenueList = r.data;
            });
            this.$api.getJobStages().then((r) => {
                this.jobStages = r.data;
            });
            this.$api.getFinancialYears().then((r) => {
                this.financialYearList = r.data;
            });
            this.$api.getResponsibleUsers().then((r) => {
                this.responsibleUsers = r.data;
            });
            this.$api.getTaskToEdit(jobId).then((r) => {
                this.task = r.data;
                this.$api.getTaskFiles(jobId).then((r) => {
                    this.files = r.data;
                });
                this.$api.getFinancialFiles(jobId).then((r) => {
                    this.financialFiles = r.data;
                });
                this.$api.getClientContactList(this.task.clientId).then((r) => {
                    this.clientContacts = r.data;
                    this.loaded = true;
                });
            });
        },
        removeComment(taskNoteId) {
            this.$api.deleteTaskNote(this.task.id, taskNoteId).then(() =>{
                this.initData();
            });
        },
        formatDate(date) {
            return moment(date).format('DD/MM/yyyy');
        },
        formatDateTime(dateTime) {
            return moment(dateTime).format('DD/MM/yyyy HH:mm');
        },
        deleteFile(jobId, file) {
            this.$api.deleteJobFile(jobId, file).then(() => {
                this.initData();
            });
        },
        deleteFinancialFile(jobId, file) {
            this.$api.deleteFinancialFile(jobId, file).then(() => {
                this.initData();
            });
        },
        saveJob() {
            this.$api.editTask(this.task.id, this.task).then(() => {
                this.showToast = true;
                setTimeout(() => {
                    this.showToast = false;
                }, 3000);
            });
        },
        markAsComplete() {
            this.task.isMarkedAsComplete = true;
            this.initData();
        },
        async saveNewContact() {
            await this.v$.$validate();
            if (!this.v$.newContactForm.$error) {
                this.$api.addNewTaskClientContact(this.task.id, this.newContactForm).then((res) => {
                    
                    this.$api.getClientContactList(this.task.clientId).then((r) => {
                        this.clientContacts = r.data;
                        this.task.clientContactId = res.data;
                        this.toggleContact();
                        this.clearNewContactForm();
                    });
                });
            }
        },
        clearNewContactForm() {
            this.newContactForm.name = '';
            this.newContactForm.phoneNumber = '';
            this.newContactForm.email = '';
            this.v$.$reset();
        },
        toggleContact() {
            this.addNewContact = !this.addNewContact;
        },
        downloadFile(file) {
            this.$api.getDownloadFile(this.task.id, file.name).then((r) => {
                // const url = window.URL.createObjectURL(new Blob([r.data]));
                var link = document.createElement('a');
                link.href = r.data;
                link.download = file.name;
                link.click()
            });
        },
        downloadFinancialFile(file) {
            this.$api.getDownloadFinancialFile(this.task.id, file.name).then((r) => {
                // const url = window.URL.createObjectURL(new Blob([r.data]));
                var link = document.createElement('a');
                link.href = r.data;
                link.download = file.name;
                link.click()
            });
        },   
        uploadFile(fileList) {
            for (let i = 0; i < fileList.length; i++) {
                this.images.append("file", fileList[i], fileList[i].name);
            }
        },
        uploadFinancialFile(fileList) {
            for (let i = 0; i < fileList.length; i++) {
                this.financials.append("file", fileList[i], fileList[i].name);
            }
        },
        submitFile() {
            this.$api.uploadTaskFiles(this.task.id, this.images).then(() => {
                this.initData();
                this.images = new FormData();
                this.$refs.file.value = null;
            });
        },
        submitFinancialFile() {
            this.$api.uploadFinancialFiles(this.task.id, this.financials).then(() => {
                this.initData();
                this.financials = new FormData();
                this.$refs.financialFile.value = null;
            });
        },
        submitNoteForm() {
            this.noteForm.messageDate = moment();
            this.$api.addNewTaskNote(this.task.id, this.noteForm).then(() => {
                this.clearNotesForm();
                this.initData();
            });
        },
        clearNotesForm() {
            this.noteForm.message = '';
            this.noteForm.messageDate = moment();
        }
    },
    beforeUnmount() {
        let navItems = document.getElementsByClassName("nav-link");
        for (let i = 0; i < navItems.length; i++) {
            if(navItems[i].classList.contains('active')){
                navItems[i].classList.remove('active');
            }
        }
    }
};
</script>
