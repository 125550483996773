<template>
  <div class="app-wrapper flex-column flex-row-fluid">
    <global-loading v-if="loading" />
    <div class="app-main flex-column flex-row-fluid" v-if="!loading">
      <div class="d-flex flex-column flex-column-fluid">
        <page-header title="Users" :links="links">
          <button
            class="btn btn-primary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_create_user"
            v-if="$auth.getRoleForUser() == 1"
          >
            Create new user
          </button>
        </page-header>
        <div class="app-container container-xxl">
          <!-- <div class="d-flex flex-column justify-content-center flex-wrap">
            <div class="input-group mb-8">
              <input
                type="text"
                class="form-control"
                placeholder="Search users..."
                v-model="searchTerm"
                aria-label="Search Users"
                aria-describedby="search-button"
                @keyup.enter="searchUsers()"
              />
              <button
                class="btn btn-icon btn-primary"
                type="button"
                id="search-button"
                @click="searchUsers()"
              >
                <i class="fas fa-solid fa-search fs-4"></i>
              </button>
            </div>
          </div> -->
          
          <user-table-list :users="users" v-on:editUser="getUser" v-on:deleteUser="getUser"/>
          

          <div class="row g-6 g-xl-9 d-lg-none">
            <div
              class="col-sm-6 col-xl-4"
              v-for="(u, index) in users"
              :key="index"
            >
              <user-list-item :user="u" v-on:editUser="getUser" />
            </div>
          </div>
          <div class="d-flex flex-stack flex-wrap pt-10">
            <div class="fs-6 fw-semibold text-gray-700">
              Showing page {{ page }} of {{ totalPages }}
            </div>
            <ul class="pagination">
              <li class="page-item previous" v-if="page > 1">
                <button class="page-link" @click="prevUrl">
                  <i class="previous"></i>&nbsp; Previous
                </button>
              </li>
              <li class="page-item next" v-if="page < totalPages">
                <button class="page-link" @click="nextUrl">
                  Next &nbsp;<i class="next"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <modal-create-user v-if="!loading" />
    <modal-edit-user v-if="!loading" :user="selectedUser" />
    <modal-delete-user v-if="!loading" :user="selectedUser"/>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
export default {
  name: "Users",
  data() {
    return {
      loading: true,
      users: [],
      page: 1,
      totalPages: 1,
      searchTerm: null,
      selectedUser: null,
      links: [
        {
          url: "/",
          title: "Dashboard",
        },
      ],
    };
  },
  created() {
    this.checkQuery();
    this.initData();
  },
  methods: {
    checkQuery() {
      const route = useRoute();
      this.page = route.query.page == null ? 1 : route.query.page;
      this.searchTerm =
        route.query.searchTerm == null ? "" : route.query.searchTerm;
    },
    initData() {
      this.loading = true;
      this.$api.getUserList().then((r) => {
        this.users = r.data;
        // let pagination = JSON.parse(r.headers.get('x-pagination'));
        // this.page = pagination.CurrentPage;
        // this.totalPages = pagination.TotalPageCount == 0 ? 1 : pagination.TotalPageCount;
        this.loading = false;
      });
    },
    getUser(user) {
      this.selectedUser = user;
    },
    closeUser() {
      this.selectedUser = null;
    },
    navigate(pageNumber) {
      if (this.searchTerm!= null && this.searchTerm.length > 0) {
        this.$router.push({
          query: { page: pageNumber, searchTerm: this.searchTerm },
        });
      } else {
        this.$router.push({ query: { page: pageNumber } });
      }
    },
    searchUsers() {
      this.navigate(1);
    },
    prevUrl() {
      this.navigate(this.page - 1);
    },
    nextUrl() {
      this.navigate(this.page + 1);
    },
  },
};
</script>
