<template>
    <div
      class="modal fade"
      id="kt_modal_view_event"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg p-9">
        <div class="modal-content modal-rounded" v-if="task != null">
          <div class="modal-header py-7 d-flex justify-content-between">
            <h2 class="m-0">{{getEventTypeDisplay(task.eventType)}}</h2>
            <div
              id="dismiss-modal"
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              ref="closeModal"
            >
              <i class="ki-solid ki-cross fs-1"></i>
            </div>
          </div>
          <div class="modal-body scroll-y m-5" v-if="user != null">
            <div class="row mb-6">
              
              <div class="col-12 d-flex mb-4" v-if="task.eventType == 'Task'">
                <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
                  <div class="symbol-label bg-info">
                    <i class="fas fa-solid fa-tools fs-4 text-dark"></i>
                  </div>
                </div>
                <p class="m-0 fs-6"><span class="text-muted d-block">Job Title</span> {{task.title}}</p>
              </div>
              <div class="col-12 d-flex mb-4">
                <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
                  <div class="symbol-label bg-info">
                    <i class="fas fa-solid fa-user fs-4 text-dark"></i>
                  </div>
                </div>
                <p class="m-0 fs-6"><span class="text-muted d-block">Assigned to</span> {{user}}</p>
              </div>
              <div class="col-12 d-flex mb-4">
                <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
                  <div class="symbol-label bg-info">
                    <i class="fas fa-solid fa-info-circle fs-4 text-dark"></i>
                  </div>
                </div>
                <p class="m-0 fs-6"><span class="text-muted d-block">Description</span> {{task.description}}</p>
              </div>
              <div class="col-12 col-md-6 d-flex mb-4" v-if="task.startDateTime != null">
                <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
                  <div class="symbol-label bg-info">
                    <i class="fas fa-solid fa-calendar fs-4 text-dark"></i>
                  </div>
                </div>
                <p class="m-0 fs-6"><span class="text-muted d-block">Start date / time</span> {{convertDate(task.startDateTime)}}</p>
              </div>
              <div class="col-12 col-md-6 d-flex mb-4" v-if="task.endDateTime != null">
                <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
                  <div class="symbol-label bg-info">
                    <i class="fas fa-solid fa-calendar fs-4 text-dark"></i>
                  </div>
                </div>
                <p class="m-0 fs-6"><span class="text-muted d-block">End date / time</span> {{convertDate(task.endDateTime)}}</p>
              </div>
            </div>
            
            <button v-if="task.eventType == 2 && ($auth.getRoleForUser() != 4)" data-bs-dismiss="modal" class="btn btn-primary me-6" @click="goToJob(task)">Edit</button>
            <button v-if="$auth.getRoleForUser() != 3 && $auth.getRoleForUser() != 4" data-bs-dismiss="modal" class="btn btn-info me-6" data-bs-toggle="modal" data-bs-target="#kt_modal_reschedule_event" @click="reschedule(task, user)">Reschedule</button>
            <button v-if="$auth.getRoleForUser() != 3 && $auth.getRoleForUser() != 4" data-bs-dismiss="modal" class="btn btn-danger me-6" @click="deleteJob(task)">Delete</button>
            <button class="btn btn-light" data-bs-dismiss="modal" @click="cancelForm()">Close</button>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script lang="js">
  import moment from "moment";
  
  export default {
    name: 'ScheduleEvent', 
    props: ['task', 'user'],
    methods: {
      convertDate(startDate) {
        return moment(startDate).format('DD-MM-yyyy HH:mm');
      },
      getEventTypeDisplay(eventType) {
        switch(eventType) {
          case 1:
            return 'Meeting';
          case 2:
            return 'Task';
          case 3:
            return 'Holiday';
          case 4:
            return 'Authorised Absence';
        }
      },
      goToJob(task) {
        this.$parent.selectedTask = null;
        this.$parent.selectedUser = null;
        this.$parent.showModal = false;
        this.$router.push({ name: 'EditJob', query: { jobId: task.complianceTaskId }});
      },
      deleteJob(task) {
        this.$parent.deleteTask(task.id);
      },
      reschedule(task, user) {
        this.$refs.closeModal.click();
        this.$parent.rescheduleTask(task, user);
      },
      cancelForm() {
        this.$refs.closeModal.click();
      }
    }
  };
  </script>