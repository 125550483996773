import { app } from "@/plugins/app";
import axios from 'axios';
import dayjs from 'dayjs'
import jwt_decode from "jwt-decode";

const baseURL = '';

let authToken = null; 
const axiosInstance = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    headers:{Authorization: `Bearer ${authToken}`}
});

axiosInstance.interceptors.request.use(async req => {
    authToken = app.config.globalProperties.$auth.getAuthToken();

    if (authToken == null ) {
        window.location.href = '/login';
        return;
    }

    const user = jwt_decode(authToken);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if(!isExpired) 
    {
        req.headers.Authorization = `Bearer ${authToken}`;
        return req;
    } else {
        window.location.href = '/login';
        return; 
    }

},
(error) => {
    if (error.response.status === 401) {
        window.location.href = '/login';
    }
    if (error.response.status === 402) {
        window.location.href = '/login';
    }
    if (error. response.status === 401) {
        window.location.href = '/login';
    }
});

export default axiosInstance;