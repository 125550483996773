import AddClient from './AddClient.vue';
import EditClient from './EditClient.vue';
import DeleteClient from './DeleteClient.vue';
import AddContactToClient from './AddContactToClient.vue';
import EditContactToClient from './EditContactToClient.vue';

export default {
    AddClient,
    EditClient,
    DeleteClient,
    AddContactToClient,
    EditContactToClient
}