<template>
    <div
      class="modal fade"
      id="kt_modal_delete_job"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-medium p-9">
        <div class="modal-content modal-rounded">
          <div class="modal-header py-7 d-flex justify-content-between">
            <h2 class="m-0">Delete a job</h2>
            <div
              id="dismiss-modal"
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              ref="closeModal"
            >
              <i class="ki-solid ki-cross fs-1"></i>
            </div>
          </div>
          <div class="modal-body scroll-y m-5">
              <div class="mb-10">
                <p>Are you sure you want to delete the job {{ this.jobNumber }} for {{ this.clientName }} </p>
              </div>
              <button class="btn btn-primary me-4" @click="submitForm()">Delete</button>
              <button class="btn btn-light" @click="cancelForm()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DeleteJob', 
    props: ["job"],
    data() { 
      return {
        id: 0,
        jobNumber:'',
        clientName: '',
      }
    },
    watch: {
      job: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.id = this.job.id;
          this.jobNumber = this.job.jobNumber;
          this.clientName = this.job.clientName;
        }
      }
    },
    methods: {
      async submitForm() {
        this.$api.deleteJob(this.id).then(() => {
            this.cancelForm();
            this.$parent.initData();
        });
      },
      async clearForm() {
        this.id = 0;
        this.jobNumber='';
        this.clientName = '';
      },
      cancelForm() {
        this.$refs.closeModal.click();
        this.clearForm();
      }
    }
  };
  </script>
  