<template>
    <div class="d-none d-lg-block">
        <div class="table-responsive">
            <table class="table table-row-dashed align-middle gs-0 gy-3 mt-0 mb-5">
                <thead>
                    <tr class="fw-semibold fs-6 text-gray-800 border-bottom border-gray-200">
                        <th width="140">Colour of Vehicle</th>
                        <th>Registration plate</th>
                        <th>Make</th>
                        <th>Model</th>
                        <th class="p-0 pb-3 w-125px text-end">Action</th>
                    </tr>   
                </thead>
                <tbody>
                    <tr v-for="(van, index) in vans" :key="index">
                        <td>
                          <div :style="'background-color: ' + van.color + '; height: 19px; width: 100%; display:block;'"></div>
                        </td>
                        <td>
                          <div class="d-flex align-items-center py-1">
                            <div class="d-flex flex-column align-items-start justify-content-center">
                              <span class="text-gray-900 fs-6 lh-1">{{van.vehicleRegistration}}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                            <div class="d-flex align-items-center py-1">
                                <div class="d-flex flex-column align-items-start justify-content-center">
                                    <span class="text-gray-900 fs-6 lh-1">{{ van.make }}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="d-flex align-items-center py-1">
                                <div class="d-flex flex-column align-items-start justify-content-center">
                                    <span class="text-gray-900 fs-6 lh-1">{{ van.model }}</span>
                                </div>
                            </div>
                        </td>
                        <td class="text-end">
                            <div v-if="$auth.getRoleForUser() == 1" class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Actions
                                </button>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#kt_modal_edit_van"
                                      :title="'Edit ' + van.vehicleRegistration"
                                      @click="editVan(van)">Edit van</a></li>
                                    <li><a class="dropdown-item" href="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#kt_modal_delete_van"
                                      :title="'Delete ' + van.vehicleRegistration"
                                      @click="deleteVan(van)">Delete van</a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </template>
  
<script>
  export default {
    props: ["vans"],
    methods: {
      editVan(van) {
        this.$emit('editVan', van)
      },
      deleteVan(van)
      {
        this.$emit('deleteVan', van)
      }
    }
  };
  </script>
  