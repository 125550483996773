<template>
  <button class="card h-100 mb-4 text-start p-0 w-100" :class="variation != '' ? variation : ''" :title="context">
    <div class="card-header flex-nowrap border-0 pt-8 pb-8">
      <div class="card-title d-flex flex-column m-0">
        <span class="fs-3 fw-bold me-2 lh-1 ls-n2" :class="variation != '' ? variation : ''">{{
          context
        }}</span
        ><span class="opacity-75 pt-1 fw-semibold fs-6" :class="variation != '' ? variation : ''">{{
          value
        }}</span>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: "StageTotalCard",
  props: ["variation", "value", "context"],
};
</script>
