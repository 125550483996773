<template>
  <div
    class="app-container container-xxl py-4 d-flex flex-lg-column py-6"
    id="kt_footer"
  >
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
    >
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted fw-semibold me-1">2023 &copy; </span>
        <a
          href="https://www.compliancelev.co.uk/"
          target="_blank"
          class="text-gray-800 text-hover-primary"
          >Compliance LEV</a
        >
      </div>
    </div>
  </div>
</template>
