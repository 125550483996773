import Client from './client';
import Jobs from './jobs';
import Users from './user';
import Vans from './van';
import Schedules from './schedule';

export default {
    Client,
    Jobs,
    Users,
    Vans,
    Schedules
}