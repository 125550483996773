import ViewJob from './ViewJob.vue';
import EditJob from './EditJob.vue';
import AddJob from './AddJob.vue';
import DeleteJob from './DeleteJob.vue';
import ScheduleJob from './ScheduleJob.vue';
import FilterJob from './FilterJobs.vue';
import MarkAsComplete from './MarkAsComplete.vue';

export default {
    ViewJob,
    EditJob,
    AddJob,
    DeleteJob,
    ScheduleJob,
    FilterJob,
    MarkAsComplete
}