<template>
  <div
    class="modal fade"
    id="kt_modal_filter_job"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg p-9">
      <div class="modal-content modal-rounded">
        <div class="modal-header py-7 d-flex justify-content-between">
          <h2 class="m-0">
            Filter Jobs
          </h2>
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <i class="ki-solid ki-cross fs-1 text-dark"></i>
          </div>
        </div>
        <div class="modal-body scroll-y m-5 p-3">
            <div class="mb-10">
                <label class="form-label">Client</label>
                <div class="position-relative">
                  <select v-model="form.clientId" id="clientId" name="clientId" class="form-select">
                    <option v-for="(clientItem, index) in clientsList" :key="index" :value="clientItem.value">{{ clientItem.label }}</option>
                  </select>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label">Financial Year</label>
                <div class="position-relative">
                  <select v-model="form.financialYear" id="financialYear" name="financialYear" class="form-select">
                    <option v-for="(year, index) in financialYearsList" :key="index" :value="year.value">{{ year.label }}</option>
                  </select>
                </div>
            </div>
            <div class="mb-10">
              <label class="form-label">Stages</label>
              <div v-for="(item, index) in items" class="form-check form-check-custom form-check-lg mb-3" :key="index">
                  <input class="form-check-input" type="checkbox" v-model="form.stages" :value="item.value" :id="'filter-' + index" />
                  <label class="form-check-label text-dark" :for="'filter-' + index">
                      {{item.label}}
                  </label>
              </div>
            </div>
            <button class="btn btn-primary me-4" data-bs-dismiss="modal" @click="submitForm()">Submit</button>
            <button class="btn btn-light" data-bs-dismiss="modal" @click="clearForm()">Clear filter</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['stages', 'client', 'financialYear', 'userProfiles'],
  data() {
    return {
        clientsList: [],
        financialYearsList: [],
        items: [],
        form: {
          clientId : "",
          financialYear: "",
          stages: [],
          userProfiles: []
        }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
        this.$api.getClientSelectList().then((r) => {
            this.clientsList = r.data;
        });
        this.$api.getJobStages().then((r) => {
            this.items = r.data;
        });
        this.$api.getFinancialYears().then((r) => {
          this.financialYearsList = r.data;
        });
        this.form.clientId = this.client;
        this.form.stages = this.stages;
        this.form.financialYear = this.financialYear;
        this.form.userProfiles = this.userProfiles;
    },
    submitForm() {
        this.$parent.applyFilter(this.form);
    },
    clearForm() {
        this.form.stages = [];
        this.form.clientId = "";
        this.form.financialYear = "";
        this.form.userProfiles = [];
        this.$parent.applyFilter(this.form);
    }
  },
};
</script>