<template>
    <div
      class="modal fade"
      id="kt_modal_create_user"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-medium p-9">
        <div class="modal-content modal-rounded">
          <div class="modal-header py-7 d-flex justify-content-between">
            <h2 class="m-0">Create new client</h2>
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <i class="ki-solid ki-cross fs-1"></i>
            </div>
          </div>
          <div class="modal-body scroll-y m-5">
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nobis fuga
              velit neque exercitationem nemo quisquam sapiente sed perferendis
              cupiditate nulla, excepturi odio earum? Dolore officiis quia totam
              labore recusandae inventore.
            </p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  