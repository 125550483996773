import Global from './global';
import Page from './page';
import Dashboard from './dashboard';
import Client from './client';
import Jobs from './jobs';
import Modals from './modals';
import Users from './user';
import Avatar from './avatar';
import Vans from './vans';

export default {
    Global,
    Page,
    Dashboard,
    Client,
    Jobs,
    Modals,
    Users,
    Avatar,
    Vans
}