<template>
    <div
      class="modal fade"
      id="kt_modal_edit_van"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-medium p-9">
        <div class="modal-content modal-rounded">
          <div class="modal-header py-7 d-flex justify-content-between">
            <h2 class="m-0">Edit van</h2>
            <div
              id="dismiss-modal"
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              ref="closeModal"
            >
              <i class="ki-solid ki-cross fs-1"></i>
            </div>
          </div>
          <div class="modal-body scroll-y m-5">
              <div class="mb-10">
                  <label for="vehicleRegistration" class="required form-label">Vehicle Registration</label>
                  <div class="position-relative">
                    <span class="text-danger" v-if="v$.form.vehicleRegistration.$error">Please enter a valid vehicle registration</span>
                    <input type="text" v-model="form.vehicleRegistration" id="vehicleRegistration" class="form-control" :class="{'is-invalid' : v$.form.vehicleRegistration.$error}" placeholder="eg: SE08 HEJ"/>
                  </div>
              </div>
              <div class="mb-10">
                  <label for="make" class="required form-label">Make</label>
                  <div class="position-relative">
                    <span class="text-danger" v-if="v$.form.make.$error">Please provide the make of the vehicle</span>
                    <input type="text" v-model="form.make" id="make" class="form-control" :class="{'is-invalid' : v$.form.make.$error}" placeholder="eg: Mazda"/>
                  </div>
              </div>
              <div class="mb-10">
                  <label for="model" class="required form-label">Model</label>
                  <div class="position-relative">
                    <span class="text-danger" v-if="v$.form.model.$error">Please provide the model of the vehicle</span>
                    <input type="text" v-model="form.model" id="model" class="form-control" :class="{'is-invalid' : v$.form.model.$error}" placeholder="eg: 2"/>
                  </div>
              </div>
              <div class="mb-10">
                <label for="color" class="required form-label">Color code</label>
                
                <span class="text-danger" v-if="v$.form.color.$error">Please provide a color to reference this vehicle</span>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text rounded-circle me-3" :style="'background-color:' + form.color">#</span>
                  </div>
                  <input type="text" id="color" v-model="form.color" class="form-control rounded" placeholder="Select colour">  
                </div>
                  <div class="text-light">
                    <ColorPicker
                      :color="form.color"
                      default-format="hex"
                      alpha-channel="hide"
                      :visible-formats="['hex']"
                      @color-change="updateColor"
                    >
                      <template #hue-range-input-label>
                        <span class="visually-hidden">Hue</span>
                      </template>

                      <template #alpha-range-input-label>
                        <span class="visually-hidden">Alpha</span>
                      </template>
                    </ColorPicker>
                  </div>
         
              </div>
              <button class="btn btn-primary me-4" @click="submitForm()">Submit</button>
              <button class="btn btn-light" @click="cancelForm()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </template>

  <style>
  .vacp-color-space {
    height: 100px;
    border: 1px solid var(--bs-gray-300);
    appearance: none;
    border-radius: 0.475rem;
  }
  .vacp-color-picker {
    background-color: transparent;
    padding: 0;
    margin: 10px 0 0 0;
    max-width: 100%;
  }
  .vacp-color-input-group,
  .vacp-copy-button {
    display: none;
  }
  </style>

  <script>
  import useVuelidate from "@vuelidate/core";
  import { required, minLength, maxLength } from "@vuelidate/validators";
  import { ColorPicker } from 'vue-accessible-color-picker';
  export default {
    name: 'EditVan', 
    props: ["van"],
    components: {
      ColorPicker,
    },
    data() { 
      return {
        v$ : useVuelidate(),
        form: {
          vehicleRegistration: '',
          make: '',
          model: '',
          color: '',
        },
        id: 0
      }
    },
    watch: {
      van: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.id = this.van.id;
          this.form.vehicleRegistration = this.van.vehicleRegistration;
          this.form.make = this.van.make;
          this.form.model = this.van.model;
          this.form.color = this.van.color;
        }
      }
    },
    validations() {
      return {
        form: {
          vehicleRegistration: { required },
          make: { required },
          model: { required },
          color: { required, minLength : minLength(7), maxLength: maxLength(7) }
        }
      }
    },
    methods: {
      updateColor(eventData) {
        this.form.color = eventData.cssColor;
      },
      async submitForm() {
          
          await this.v$.$validate();
          if(!this.v$.$error) {
            this.$api.editVan(this.id, this.form).then(() => {
              this.cancelForm();
              this.$parent.initData();
            });
          }
      },
      async clearForm() {
        this.form.vehicleRegistration = '';
        this.form.make = '';
        this.form.model = '';
        this.form.color = '';
        this.id = 0;
        this.$v.reset();
      },
      cancelForm() {
        this.$refs.closeModal.click();
        this.clearForm();
      }
    }
  };
  </script>
  