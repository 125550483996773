<template>
  <div class="app-wrapper flex-column flex-row-fluid">
    <div class="app-main flex-column flex-row-fluid">
      <div class="d-flex flex-column flex-column-fluid">
        <page-header :title="'Welcome, ' + $auth.getLoggedInUserName()" :links="links" />
        <div class="app-container container-xxl">
          <div class="row g-6 g-xl-9">
            <div class="col-sm-6 col-xl-3">
              <info-card
                variation="success"
                :value="allocatedJobs"
                context="Jobs assigned to you"
                link="assigned-jobs"
              ></info-card>
            </div>
            <div class="col-sm-6 col-xl-3">
              <info-card
                variation="danger"
                :value="completedJobs"
                context="Completed jobs assigned to you"
                link="completed-jobs"
              ></info-card>
            </div>
            <div class="col-sm-6 col-xl-3">
              <info-card
                variation="light"
                :value="commentedJobs"
                context="New comments on jobs"
                link="commented-jobs"
              ></info-card>
            </div>
            <div class="col-sm-6 col-xl-3">
              <info-card
                variation="info"
                :value="outstandingQuotations"
                context="Outstanding quotations"
                link="overdue-jobs"
              ></info-card>
            </div>
          </div>

          <div class="row g-6 g-xl-9 mt-0">
            <div class="col-sm-6">
              <dashboard-latest-jobs :jobs="updatedTasks"/>
            </div>
            <!-- <div class="col-sm-6">
              <dashboard-latest-jobs :jobs="updatedTasks"/>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  data() {
    return {
      userId: 0,
      allocatedJobs: 0,
      completedJobs: 0,
      commentedJobs: 0,
      outstandingQuotations: 0,
      updatedTasks: [],
      links: [],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.userId =  this.$auth.getUserProfileId();
      this.$api.getJobsAllocatedToMe(this.userId).then((r) => {
        this.allocatedJobs = r.data;
      });
      this.$api.getJobsCompleted(this.userId).then((r) => {
        this.completedJobs = r.data;
      });
      this.$api.getCommentsOnJobs(this.userId).then((r) => {
        this.commentedJobs = r.data;
      });
      this.$api.getOutstandingQuotations().then((r) => {
        this.outstandingQuotations = r.data;
      });
      this.$api.getRecentlyUpdatedTasks(this.userId).then((r) => {
        this.updatedTasks = r.data;
      });
    }
  }
};
</script>
