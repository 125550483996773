<template>
    <div
      class="modal fade"
      id="kt_modal_edit_client"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-medium p-9">
        <div class="modal-content modal-rounded">
          <div class="modal-header py-7 d-flex justify-content-between">
            <h2 class="m-0">Edit client</h2>
            <div
              id="dismiss-modal"
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              ref="closeModal"
            >
              <i class="ki-solid ki-cross fs-1"></i>
            </div>
          </div>
          <div class="modal-body scroll-y m-5">
              <div class="mb-10">
                  <label for="clientName" class="required form-label">Client name</label>
                  <div class="position-relative">
                    <span class="text-danger" v-if="v$.form.clientName.$error">Please enter the client name</span>
                    <input type="text" v-model="form.clientName" id="clientName" class="form-control" :class="{'is-invalid' : v$.form.clientName.$error}" placeholder="eg: Company name LTD"/>
                  </div>
              </div>
              <div class="mb-10">
                  <label for="address" class="form-label">Address</label>
                  <div class="position-relative">
                    <input type="text" v-model="form.address" id="address" class="form-control" placeholder="eg: 1 Main Street"/>
                  </div>
              </div>
              <div class="mb-10">
                  <label for="city" class="form-label">City</label>
                  <div class="position-relative">
                    <input type="text" v-model="form.city" id="city" class="form-control" placeholder="eg: Glasgow"/>
                  </div>
              </div>
              <div class="mb-10">
                  <label for="postcode" class="form-label">Postcode</label>
                  <div class="position-relative">
                    <input type="text" v-model="form.postcode" id="postcode" class="form-control" placeholder="eg: G1 2AB"/>
                  </div>
              </div>
              <button class="btn btn-primary me-4" @click="submitForm()">Submit</button>
              <button class="btn btn-light" @click="cancelForm()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
//import { reactive, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: 'EditClient',
  props: ["client"],
  data() { 
    return {
      v$ : useVuelidate(),
      form: {
        clientName: '',
        address: '',
        city: '',
        postcode: ''
      },
      id: 0
    }
  },
  watch: {
    client: function (newVal, oldVal) {
        if (newVal != oldVal) {
            this.id = this.client.id;
            this.form.clientName = this.client.clientName;
            this.form.address = this.client.address;
            this.form.city = this.client.city;
            this.form.postcode = this.client.postcode;
        }
    }
  },
  validations() {
    return {
      form: {
        clientName: { required }, 
      }
    }
  },
  methods: {
    async submitForm() {
        await this.v$.$validate();
        if(!this.v$.$error) {
          this.$api.editClient(this.id, this.form).then(() => {
            this.cancelForm();
            this.$parent.initData();
          });
        }
    },
    async clearForm() {
      this.form.clientName = '';
      this.form.clientAddress = '';
      this.form.clientCity = '';
      this.form.clientPostcode = '';
    },
    cancelForm() {
      this.$refs.closeModal.click();
      this.clearForm();
    }
  }
};
</script>