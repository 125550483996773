<template>
  <div class="app-wrapper flex-column flex-row-fluid">
    <div class="toast bg-primary" :class="{'show': showToast }" role="alert" aria-live="assertive" aria-atomic="true"  style="position: absolute; top: 88px; right: 16px;">
        <div class="toast-body text-dark fs-5">
            <i class="fa-solid fa-circle-check me-4 text-dark" aria-hidden="true"></i>Password has been updated
        </div>
    </div>
    <div class="app-main flex-column flex-row-fluid">
      <div class="d-flex flex-column flex-column-fluid">
        <page-header title="My profile" :links="links" />
        
        <div class="py-3 py-lg-6 w-100">
          <div class="app-container container-xxl">
          
            <ul class="nav nav-tabs nav-line-tabs mb-5 fs-3 fw-bold">
              <li class="nav-item">
                  <a class="nav-link text-white" data-bs-toggle="tab" href="#kt_tab_pane_1">Assigned Jobs</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link text-white active" data-bs-toggle="tab" href="#kt_tab_pane_2">Account settings</a>
              </li>
          </ul>

          
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show" id="kt_tab_pane_1" role="tabpanel">
              <div class="table-responsive">
              <table class="table table-row-dashed align-middle gs-0 gy-3 my-0">
                  <thead>
                      <tr class="fw-semibold fs-6 text-gray-800 border-bottom border-gray-200">
                          <th class="ps-2 pb-3 w-150px">Job ID</th>
                          <th class="pb-3">Client name</th>
                      </tr>   
                  </thead>
                  <tbody>
                      <tr>
                        <td>#23232</td>
                        <td>Example Job</td>
                      </tr>
                  </tbody>
                  </table>
                  </div>
            </div>
            <div class="tab-pane fade show active" id="kt_tab_pane_2" role="tabpanel">


          <div class="row g-6 g-xl-9 mb-8 mt-4">
              <div class="col-12 col-md-6">
              <div class="card mb-10">
                  <div class="card-body">
                      <h3 class="mb-6">User details</h3>

                      <div class="mb-10">
                          <label for="firstName" class="required form-label">First name</label>
                          <div class="position-relative">
                              <input type="text" id="firstName" class="form-control" placeholder="" v-model="form.firstName">
                          </div>
                      </div>
                      <div class="mb-10">
                          <label for="firstName" class="required form-label">Surname</label>
                          <div class="position-relative">
                              <input type="text" id="firstName" class="form-control" placeholder="" v-model="form.lastName">
                          </div>
                      </div>
                      <div class="mb-10">
                          <label for="firstName" class="required form-label">Email address</label>
                          <div class="position-relative">
                              <input type="text" id="firstName" class="form-control" placeholder="" v-model="form.email" disabled>
                          </div>
                      </div>
                      
                      <button class="btn btn-primary" @click="submitForm()">Update account</button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="card mb-10">
                  <div class="card-body">
                      <h3 class="mb-6">Password</h3>

                      <div class="mb-10">
                          <label for="password" class="required form-label">Password</label>
                          <div class="position-relative">
                            <span class="text-danger" v-if="v$.passwordForm.password.$error">Please ensure password is at least 10 characters long</span>
                            <input type="password" id="password" class="form-control" placeholder="" v-model="passwordForm.password" :class="{'is-invalid' : v$.passwordForm.password.$error}">
                          </div>
                      </div>
                      <div class="mb-10">
                          <label for="repeatPassword" class="required form-label">Repeat Password</label>
                          <div class="position-relative">
                            <span class="text-danger" v-if="v$.passwordForm.repeatPassword.$error">Please ensure passwords match</span>
                            <input type="password" id="repeatPassword" class="form-control" placeholder="" v-model="passwordForm.repeatPassword" :class="{'is-invalid' : v$.passwordForm.repeatPassword.$error}" />
                          </div>
                      </div>
                      <button class="btn btn-primary" @click="submitPasswordForm()">Update password</button>
                  </div>
                  </div>
              </div>
          </div>
        
          
        </div>
          </div>
        </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, email, sameAs } from "@vuelidate/validators";
export default {
  name: "My profile",
  data() {
    return {
      userId: 0,
      v$ : useVuelidate(),
      links: [
        {
          url: "/",
          title: "Dashboard",
        },
      ],
      form: {
        firstName: '',
        lastName: '',
        email: '',
        role: 0,
        canAssignTasks:false
      },
      passwordForm:{
        password: '',
        repeatPassword: ''
      },
      showToast: false,
    };
  },
  validations(){
    return{
      form:{
        firstName: {required},
        lastName: {required},
        email: {required, email},
      },
      passwordForm:{
        password:{required, minLength: minLength(10)},
        repeatPassword:{required, sameAs: sameAs(this.passwordForm.password)}
      }
    }
  },
  methods: {
    async clearPasswordForm() {
      this.passwordForm.password = '';
      this.passwordForm.repeatPassword = '';
    },
    async submitForm(){
      
      await this.v$.$validate();
      if(!this.v$.form.$error) {
        this.$api.editUser(this.userId, this.form).then(() => {
          this.initData();
        })
      }
    },
    async submitPasswordForm(){
      
      await this.v$.$validate();
      if(!this.v$.passwordForm.$error){
        this.$api.editUserPassword(this.userId, this.passwordForm).then(() =>{
          this.v$.$reset();
          this.cancelForm();
          this.showToast = true;
          setTimeout(() => {
            this.showToast = false;
          }, 3000);
        })
      }
    },
    cancelForm(){
      this.clearPasswordForm();
    },
    initData(){
      this.userId =  this.$auth.getUserProfileId();
      this.$api.getUserToEdit(this.userId).then((r) =>{
        this.form.firstName = r.data.firstName;
        this.form.lastName = r.data.lastName;
        this.form.email = r.data.email;
        this.form.role = r.data.role;
        this.form.canAssignTasks = r.data.canAssignTasks;
      });
    },
  },
  created(){
    this.initData();
  }
};
</script>
