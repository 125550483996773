<template>
    <router-view :key="$route.fullPath"  v-if="$route.name === 'login' || $route.name === 'forgot-password'"></router-view>
    <div v-else class="d-flex flex-column flex-root app-root">
      <div class="app-page flex-column flex-column-fluid">
        <global-header />
        <router-view :key="$route.fullPath"></router-view>
        <global-footer />
      </div>
    </div>
</template>

<script>
export default {
  name: "App"
};
</script>
