import { createWebHistory, createRouter } from "vue-router";
import { app } from "@/plugins/app";
import dayjs from 'dayjs'
import jwt_decode from "jwt-decode";

import Dashboard from '@/views/Dashboard';
import Jobs from '@/views/Jobs';
import Clients from '@/views/Clients';
import Schedule from '@/views/Schedule';
import Users from '@/views/Users';
import Reports from '@/views/Reports';
import Settings from '@/views/Settings';
import Profile from '@/views/Profile';
import EditJob from '@/views/EditJob';
import Vans from '@/views/Vans';
import Login from '@/views/Login';
import ForgotPassword from '@/views/ForgotPassword';
import Contacts from '@/views/Contacts';

const routes = [
  { path: '/', name: 'Dashboard', component: Dashboard },
  { path: '/jobs', name: 'Jobs',  component: Jobs },
  { path: '/assigned-jobs', name: 'AssignedJobs', component: Jobs },
  { path: '/completed-jobs', name: 'CompletedJobs', component: Jobs },
  { path: '/commented-jobs', name: 'CommentedJobs', component: Jobs },
  { path: '/overdue-jobs', name: 'OverdueJobs', component: Jobs },
  { path: '/clients', name: 'Clients',  component: Clients },
  { path: '/vans', name: 'Vans', component: Vans},
  { path: '/schedule', name: 'Schedule',  component: Schedule },
  { path: '/edit-job', name: 'EditJob', component: EditJob },
  { path: '/users', name: 'Users', component: Users },
  { path: '/profile', name: 'Profile', component: Profile },
  { path: '/reports' , name: 'reports',  component: Reports },
  { path: '/settings' , name: 'settings',  component: Settings },
  { path: '/login' , name: 'login',  component: Login },
  { path: '/client-contacts' , name: 'Contacts',  component: Contacts },
  { path: '/forgot-password' , name: 'forgot-password',  component: ForgotPassword }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  const publicPages = ['/login', '/forgot-password'];
  const authRequired = !publicPages.includes(to.path);
  const authToken = app.config.globalProperties.$auth.getAuthToken();
  
  window.KTDrawer.init();
  let isExpired = true;
  if(authToken != null){
    const user = jwt_decode(authToken);
    isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
  }

  if (authRequired && isExpired) {
    return '/login';
  }

});

export default router;