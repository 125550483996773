<template>
  <div class="card h-100">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bold fs-3 mb-1"
          >Most recently updated jobs</span
        >
        <span class="text-muted mt-1 fw-semibold fs-7"
          >showing the last 10 jobs last updated</span
        >
      </h3>
      <div class="card-toolbar">
        <ul class="nav" role="tablist">
          <li class="nav-item" role="presentation">
            <a href="/jobs" class="nav-link btn btn-sm btn-primary fw-bold px-4 me-1"
              >View all jobs</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table align-middle gs-0 gy-3">
          <thead>
            <tr>
              <th class="p-0 w-50px"></th>
              <th class="p-0 min-w-150px"></th>
              <th class="p-0 min-w-120px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(job, index) in jobs" :key="index">
              <td>
                <div
                  class="symbol symbol-45px w-45px h-45px me-5"
                  :class="iconBackground"
                >
                  <i
                    class="fa-solid fa-users fs-2 mt-4 ms-3"
                    :class="iconBackground"
                  ></i>
                </div>
              </td>
              <td>
                <router-link
                  :to="'/edit-job?jobId=' + job.id"
                  class="text-success fw-bold text-hover-primary mb-1 fs-6"
                  >{{ job.clientName }}</router-link
                >
                <span class="text-muted fw-semibold d-block fs-7"
                  >#{{ job.jobNumber }}</span
                >
              </td>
              <td class="text-end">
                <span class="text-dark fw-bold d-block fs-7">{{
                  timestamp(job.lastUpdatedDate)
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "LatestJobs",
  props: ["jobs"],
  data() {
    return {
      colours: ["success", "info", "warning", "danger"],
    };
  },
  methods: {

    timestamp(dateTime) {
      let time = moment(dateTime).format("MMMM Do YYYY, h:mm a");
      return time.toString();
    },
  },
  computed: {
    iconBackground() {
      var chosenNumber = Math.floor(Math.random() * this.colours.length);
      return (
        "bg-light-" +
        this.colours[chosenNumber] +
        " text-" +
        this.colours[chosenNumber]
      );
    },
  },
};
</script>
