<template>
    <div
        class="symbol symbol-45px w-45px h-45px me-3"
        :class="iconBackground"
        >
        <div class="symbol-label fs-3 fw-semibold" :class="iconBackground">
        {{
            data
            .split(" ")
            .map((word) => word.charAt(0))
            .join("")
            .slice(0,2)
            .toUpperCase()
        }}
        </div>   
    </div> 
</template>

<script>
export default {
    props: ["data"],
    data() {
        return {
            colours: [
            "bg-light-success text-success",
            "bg-light-info text-info", 
            "bg-light-warning text-warning", 
            "bg-light-danger text-danger", 
            "bg-light-tertiary text-tertiary",
            "bg-success text-light", 
            "bg-info text-white", 
            "bg-warning text-light", 
            "bg-danger text-white", 
            "bg-tertiary text-light"],
        };
    },
    computed: {
        iconBackground() {
            var chosenNumber = Math.floor(Math.random() * this.colours.length);
            return (this.colours[chosenNumber]);
        },
    }
}
</script>