<template>
    <div style="height: 75vh">
        <div class="overlay overlay-block card-rounded h-100">
            <div class="overlay-layer card-rounded bg-light bg-opacity-5">
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>
